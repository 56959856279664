import { gql } from '@apollo/client'

export const CHAT = gql`
  query($id: ID!) {
    chat(id: $id) {
      id
      participant {
          id
          profilePicPath
          sfContact {
            firstName
            lastName
            id
              sfAccountContactRelation {
                sfAccount {
                    cdpapCoordinator {
                      user {
                        id
                        firstName
                        lastName
                      }
                    }
                }
              }
          }
        }
      status
      createdAt
      terminatedAt
      chatAgents {
        agent {
          id
          firstName
          lastName  
        }
      }
      unreadMessageCount
      latestMessage{
        id
        message
        sentAt
        agentId
      }

    }
  }
`

export const OPEN_CG_CHAT = gql`
  query($caregiverId: String!) {
    openCgChat(caregiverId: $caregiverId) {
      id
    }
  }
`

export const CHAT_MESSAGES_BY_ID = gql`
  query($data: ChatMessagesInput!) {
    chatMessagesById(data: $data) {
      count
      results {
        id
        message
        sentAt
        readAt
        updatedAt
        agentId
        chatId
        participant {
          id
          sfContact {
            firstName
            lastName
            id
          }
        }
        agent {
          id
          firstName
          lastName  
        }
      }
    }
  }
`

export const CHATS = gql`
  query($data: ChatsInput!) {
    chats(data: $data) {
      count
      results {
        id 
        status
        createdAt
        terminatedAt
      }
    }
  }
`

export const UNREAD_MESSAGE_COUNT = gql`
  query {
    unreadMessageCount 
  }
`

export const CREATE_CHAT = gql`
  mutation($data: CreateChatInput!) {
    createChat(data: $data) {
      id
      participant {
        id
        sfContact {
          firstName
          lastName
          id
        }
      }
      status
      createdAt
       terminatedAt
            chatAgents {
        agent {
          id
          firstName
          lastName  
        }
      }
      chatMessages {
        id
        message
        sentAt
        readAt
        updatedAt
        participant {
          id
          sfContact {
            firstName
            lastName
            id
          }
        }
        agent {
          id
          firstName
          lastName  
        }
      }
    }
  }
`
export const READ_MESSAGES = gql`
  mutation($ids: [ID!]!) {
    readMessages(ids: $ids) {
      id
    }
  }
`
export const UPDATE_CHAT_SETTINGS = gql`
  mutation($data: UpdateChatSettingsInput!) {
    updateChatSettings(data: $data){
      startDate
    }
  }
`

export const UPDATE_CHAT_EXPIRATION = gql`
  mutation($data: UpdateChatExpirationInput!) {
    updateChatExpiration(data: $data)
  }
`

export const CHAT_SETTINGS = gql`
  query {
    chatSettings {
      coordinatorIds
      endDate 
      status
      startDate
    }
  }
`

export const CHAT_EXPIRATION = gql`
  query {
    chatExpiration {
      expiration
      expire
    }
  }
`

export const UPDATE_CHAT = gql`
  mutation($data: UpdateChatInput!) {
    updateChat(data: $data) {
      id
      participant {
        id
        sfContact {
          firstName
          lastName
          id
        }
      }
      status
      createdAt
       terminatedAt
            chatAgents {
        agent {
          id
          firstName
          lastName  
        }
      }
      chatMessages {
        id
        message
        sentAt
        readAt
        updatedAt
        participant {
          id
          sfContact {
            firstName
            lastName
            id
          }
        }
        agent {
          id
          firstName
          lastName  
        }
      }
    }
  }
`
export const CHAT_UPDATED = gql`
  subscription {
    chatUpdated {
      id
    }
    }
`
export const CHAT_UPDATED_BY_ID = gql`
  subscription($chatId: ID!) {
    chatUpdatedById(chatId: $chatId) {
      id
    }
  }
`
