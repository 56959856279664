export const addUserFeedbackScript = () => {
  const inner = `(function () {
    window.Userback = window.Userback || {};
    Userback.access_token = '32969|61431|AvdBJDmDh4FiwXP1Gajl1cH6T';
    (function(d) {
        var s = d.createElement('script');s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
    })(document);
  })();`

  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.id = 'userfeedback'
  script.innerHTML = inner

  document.body.appendChild(script)
}
