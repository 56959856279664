import { gql } from '@apollo/client'

export const UPDATE_TIMEENTRY = gql`
  mutation($data:UpdateTimeEntry) {
        updateTimeEntry(data:$data){
            id
            date 
            duration 
            note 
            clockIn
            clockOut
            }
        }
`

export const TIME_ENTRIES = gql`
query($data: TimeEntryQueryInput){
  timeEntries(data:$data){
    results{
      id
      user {
        id
        firstName
        lastName
      }
      duration
      date
      clockIn
      clockOut
      note
    }
  }
}
`
export const TIME_ENTRY = gql`
query($id: ID){
  timeEntry(id:$id){
    id
    user {
      id
      firstName
      lastName
    }
    duration
    date
    clockIn
    clockOut
    note
  }
}
`

export const DELETE_TIMEENTRY = gql`
  mutation($id: ID!) {
    deleteTimeEntry(id: $id)
  }
`
export const CREATE_TIMEENTRY = gql`
  mutation($data: CreateTimeEntryInput){
    createTimeEntry(data: $data){
      id
      duration
      date
      clockIn
      clockOut
      user{
        id
      }
    }
  }
`
