import { gql } from '@apollo/client'

export const START_STOPWATCH = gql`
    mutation($data: StartStopwatchInput) { 
        startStopwatch(data: $data) {
            id
            clockIn
            clockOut
            note
        }
    }
`

export const END_STOPWATCH = gql`
    mutation($data: EndStopwatchInput) {
        endStopwatch(data: $data) {
            id
        }
    }
`

export const CURRENT_STOPWATCH = gql`
    query {
        currentStopwatch {
            id 
            clockIn
            clockOut
            note
        }
    }
`

export const UPDATE_STOPWATCH = gql`
    mutation($data: StartStopwatchInput) {
        updateStopwatch(data: $data) {
            id
            clockIn
            clockOut
            note
        }
    }
`

export const STOPWATCH_CHANGED = gql`
    subscription {
        stopwatchChanged {
            id
            clockIn
            clockOut
            note
            userId
        }
    }
`
