import { gql } from '@apollo/client'

export const CREATE_CAREGIVER_NOTE = gql`
    mutation($note: CreateCaregiverNoteInput!) {
        createCaregiverNote(note: $note){
            id
            date
            caregiverId
            note
        }
    }
`

export const CREATE_PATIENT_NOTE = gql`
    mutation($note: CreatePatientNoteInput!) {
        createPatientNote(note: $note){
            id
            date
            patientId
            note
        }
    }
`

export const CREATE_CLOCK_NOTE = gql`
    mutation($note: CreateClockNoteInput!){
        createClockNote(note: $note){
            id
            date
            clockId
            note
        }
    }
`

export const CLOCK_NOTES = gql`
    query($clockId: String!){
        clockNotes(clockId: $clockId){
            note
            id
            date
            clock{
                id
            }
        }
    }
`

export const CAREGIVER_NOTES = gql`
    query($data: NotesQueryInput!){
        caregiverNotes(data: $data){
            note
            id
            date
            patientId
            caregiverId
            caregiver{
                id
            }
            createdBy {
                id
                firstName
                lastName
            }
            createdAt
        }
    }
`

export const PATIENT_NOTES = gql`
    query($data: NotesQueryInput!){
        patientNotes(data: $data){
            note
            id
            date
            patientId
            caregiverId
            patient{
                id
            }
            createdBy {
                id
                firstName
                lastName
            }
            createdAt
        }
    }
`

export const DELETE_CLOCK_NOTE = gql`
    mutation($id: ID!){
        deleteClockNote(id: $id){
            id
        }
    }
`
export const DELETE_CAREGIVER_NOTE = gql`
    mutation($id: ID!){
        deleteCaregiverNote(id: $id){
            id
        }
    }
`
export const DELETE_PATIENT_NOTE = gql`
    mutation($id: ID!){
        deletePatientNote(id: $id){
            id
        }
    }
`

export const UPDATE_CAREGIVER_NOTE = gql`
    mutation($note: UpdateCaregiverNoteInput!) {
        updateCaregiverNote(note: $note){
            id
            date
            caregiverId
            note
        }
    }
`

export const UPDATE_PATIENT_NOTE = gql`
    mutation($note: UpdatePatientNoteInput!) {
        updatePatientNote(note: $note){
            id
            date
            patientId
            note
        }
    }
`

export const UPDATE_CLOCK_NOTE = gql`
    mutation($note: UpdateClockNoteInput!){
        updateClockNote(note: $note){
            id
            date
            clockId
            note
        }
    }
`
