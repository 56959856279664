import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles';
import { Topbar } from './components'

const PREFIX = 'Auth';

const classes = {
  content: `${PREFIX}-content`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.content}`]: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}));

interface AuthLayoutProps {
  children?: ReactNode
}

const Auth = ({ children }: AuthLayoutProps) => (
  <Root>
    <Topbar />
    <main className={classes.content}>{children}</main>
  </Root>
);

export default Auth
