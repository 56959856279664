import { gql } from '@apollo/client';

export default gql`
directive @cacheControl(
  maxAge: Int
  scope: CacheControlScope
) on FIELD_DEFINITION | OBJECT | INTERFACE

scalar Date

type Query {
  _: Boolean
  users(data: UsersQuery): PaginatedUsers
  user(id: ID!): User
  getCurrentUser: User
  clients(data: ClientQueryInput!): ClientQueryResult
  client(id: ID!): Client!
  timeEntries(data: TimeEntryQueryInput): TimeEntriesQueryResult!
  timeEntry(id: ID!): TimeEntry
  currentStopwatch: Stopwatch
  timesheets(data: TimesheetsQueryInput): TimesheetsQueryResult
  invoices(data: InvoiceQuery): [Invoice]
  invoice(id: ID): InvoiceDetail
  patients(data: SFPatientQuery): SFPaginatedPatients
  patient(id: ID!): SFPatient
  caregivers(data: SFCaregiverQuery!): SFPaginatedCareGivers
  caregiver(id: ID!): SFCaregiver
  sfUsers(data: SFUsersQuery): PaginatedSFUsers
}

type Mutation {
  _: Boolean
  updateUser(user: UserInput): User!
  createUser(user: UserCreateInput): User!
  signIn(email: String!, password: String!): SignInResponse!
  forgotPassword(email: String!): Boolean
  isResetPasswordValid(email: String!, passwordReset: String!): Boolean
  resetPassword(
    email: String!
    password: String!
    passwordReset: String!
  ): Boolean
  refreshToken(refreshToken: String!): RefreshTokenResponse!
  logout(refreshToken: String): Boolean!
  createClient(data: CreateClientInput): Client
  updateClient(data: UpdateClientinput): Client
  startStopwatch(data: StartStopwatchInput): Stopwatch
  endStopwatch(data: EndStopwatchInput): TimeEntry
  updateStopwatch(data: StartStopwatchInput): Stopwatch
  createTimeEntry(data: CreateTimeEntryInput): TimeEntry
  updateTimeEntry(data: UpdateTimeEntry): TimeEntry
  deleteTimeEntry(id: ID!): ID
  confirmDate(data: ConfirmDateInput): Boolean
  unconfirmDate(data: ConfirmDateInput): Boolean
  createInvoice(data: CreateInvoiceInput!): Invoice
}

type Subscription {
  _: Boolean
  stopwatchChanged: StopwatchChangeResponse
}

input UserInput {
  id: ID!
  firstName: String
  lastName: String
  phone: String
  email: String
  password: String
  role: String
  inactive: Boolean
  sfUserId: ID
}

input UserCreateInput {
  firstName: String!
  lastName: String!
  phone: String!
  email: String!
  password: String
  role: String
  inactive: Boolean
  sfUserId: ID
}

type PaginatedUsers {
  count: Int
  results: [User!]
}

input UsersQuery {
  search: String
  skip: Int
  take: Int
}

type SignInResponse {
  refreshToken: String!
  token: String!
  user: User!
}

type RefreshTokenResponse {
  refreshToken: String!
  token: String!
}

type User {
  id: ID!
  email: String
  firstName: String
  lastName: String
  inactive: Boolean
  phone: String
  role: String
  sfUser: SFUser
}

type Client {
  id: ID!
  name: String
  email: String
  inactive: Boolean
}

type ClientQueryResult {
  results: [Client!]
  count: Int
}

input CreateClientInput {
  name: String!
  email: String
  inactive: Boolean
}

input UpdateClientinput {
  id: ID!
  name: String
  email: String
  inactive: Boolean
}

input ClientQueryInput {
  skip: Int
  take: Int
  search: String
  inactive: Boolean
}

input StartStopwatchInput {
  note: String
}

type StopwatchChangeResponse {
  id: ID!
  clockIn: Date!
  clockOut: Date
  note: String
  userId: ID!
}

type Stopwatch {
  id: ID
  clockIn: Date
  clockOut: Date
  note: String
}

input EndStopwatchInput {
  note: String
}

input TimeEntryQueryInput {
  billedFor: Boolean
  clientId: ID
  userId: ID
  dateFrom: Date
  dateTo: Date
  skip: Int
  take: Int
}

type TimeEntry {
  id: ID!
  user: User!
  date: Date!
  duration: Float!
  note: String
  clockIn: Date
  clockOut: Date
}

type TimeEntriesQueryResult {
  results: [TimeEntry]
}

input CreateTimeEntryInput {
  userId: ID!
  date: Date!
  duration: Float!
  note: String
  clockIn: String
  clockOut: String
}

input UpdateTimeEntry {
  id: ID!
  userId: ID
  date: Date
  note: String
  duration: Float
  clockIn: String
  clockOut: String
}

input ConfirmDateInput {
  userId: ID
  date: String!
}

input TimesheetsQueryInput {
  dateFrom: String!
  dateTo: String!
  skip: Int
  take: Int
  search: String
}

type TimesheetResult {
  user: User!
  timesheets: [Timesheet]
}

type Timesheet {
  confirmed: Boolean!
  timeEntries: [TimeEntry!]
  totalTracked: Int
  totalClocked: Int
  date: String
}

type TimesheetsQueryResult {
  results: [TimesheetResult!]
  count: Int
}

type Note {
  id: ID
  title: String
  note: String!
  createdBy: User
  createdAt: String
}

input CreateInvoiceInput {
  name: String
  billedAt: Date
  timeEntryIds: [ID]!
  clientId: ID!
}

input InvoiceQuery {
  before: Date
  after: Date
  name: String
  clientId: ID
}

type Invoice {
  id: ID!
  name: String
  billedAt: Date!
  createdAt: Date
  clientId: ID!
}

type InvoiceDetail {
  id: ID!
  name: String
  billedAt: Date!
  createdAt: Date
  timeEntries: [TimeEntry]
  client: Client!
}

type SFPaginatedPatients {
  count: Int
  results: [SFPatient]
}

type SFPatient {
  id: ID!
  firstName: String
  lastName: String
  shippingStreet: String
  shippingCity: String
  shippingState: String
  shippingPostalCode: String
  accountType: String
  sfPatientCaregiverRelation: [PatientCaregiverRelation]
  sfAuthorizations: [Authorizations]
}

input SFPatientQuery {
  search: String
  postalCode: String
  skip: Int
  take: Int
}

type SFPaginatedCareGivers {
  count: Int
  results: [SFCaregiver]
}

type SFCaregiver {
  id: ID!
  firstName: String
  lastName: String
  email: String
  name: String
  createdDate: String
  sfPatientCaregiverRelation: [PatientCaregiverRelation]
  paStatus: String
  paType: String
  caregiverType: String
}

input SFCaregiverQuery {
  search: String
  active: Boolean
  patientId: ID
  skip: Int
  take: Int
}

type PatientCaregiverRelation {
  id: String
  sfPatient: SFPatient
  sfPatientId: String
  sfCaregiver: SFCaregiver
  sfCaregiverId: String
  isActive: Boolean
  roleType: String
}

input SFUsersQuery {
  search: String
  skip: Int
  take: Int
}

type PaginatedSFUsers {
  count: Int
  results: [SFUser!]
}

type SFUser {
  id: ID!
  fullName: String
  email: String
}

type Authorizations {
  id: String
  activeAuth: Boolean
  authStartDate: Date
  authEndDate: Date
  hoursPerWeek: Int
  daysPerWeek: Int
  daysPerWeek2: Int
  hoursPerDay: Int
  hoursPerDay2: Int
  sundayHours: Int
  mondayHours: Int
  tuesdayHours: Int
  wednesdayHours: Int
  thursdayHours: Int
  fridayHours: Int
  saturdayHours: Int
  weeklyHoursPerDay: Int
  mutualCase: Boolean
  liveIn: Boolean
}

enum CacheControlScope {
  PUBLIC
  PRIVATE
}

# The 'Upload' scalar type represents a file upload.
scalar Upload
`
