import React from 'react'
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  AppBar, Toolbar, Typography, Link
} from '@mui/material'

const PREFIX = 'Topbar';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.root}`]: {
    boxShadow: 'none'
  },

  [`& .${classes.title}`]: {
    color: 'white'
  }
}));

const Topbar = (props: any) => {
  const { className, ...rest } = props

  return (
    <StyledAppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Link component={RouterLink} to="/">
          <Typography variant="h3" className={classes.title}>
            Link Homecare
          </Typography>
        </Link>
      </Toolbar>
    </StyledAppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
}

export default Topbar
