import { differenceInMilliseconds } from 'date-fns'
import moment from 'moment'

export * from './readFile'
export * from './validation'
export * from './helpers'
export * from '../apollo/apollo'
export * from './timeHelpers'
export * from './ruleMappings'

export const formatCurrency = (amount: number | string) => Number(amount).toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const formatPhoneNumber = (phoneNumber) => {
  const input = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
  const size = input.length
  if (size > 6) { return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}` }
  if (size > 3) { return `(${input.slice(0, 3)}) ${input.slice(3)}` }
  if (size > 0) { return `(${input}` }
  return input
}

export const roundClockToNearest15min = (clockIn, clockOut) => {
  if (!clockOut) return null
  const hrsWorked = differenceInMilliseconds(new Date(clockOut), new Date(clockIn)) / 3600000
  return roundToNearest15min(hrsWorked)
}

export const formatNumber = (number) => {
  if (!number) return number
  if (Number.isInteger(number)) {
    return number
  }
  const roundedNumber = number.toFixed(2)
  // check for .oo after it was rounded
  if (Number.isInteger(+roundedNumber)) {
    return +roundedNumber
  }
  return roundedNumber;
}

export const roundToNearest15min = (hours) => {
  if (!hours) return 0
  const splitByDec = hours?.toString()?.split('.')
  const minutes = (parseFloat('.'.concat(splitByDec[1] || '0'))) * 60
  if (minutes >= 0 && minutes < 7.5) {
    return parseFloat(splitByDec[0]?.concat('.00'))
  }
  if (minutes >= 7.5 && minutes < 22.5) {
    return parseFloat(splitByDec[0]?.concat('.25'))
  }
  if (minutes >= 22.5 && minutes < 37.5) {
    return parseFloat(splitByDec[0]?.concat('.50'))
  }
  if (minutes >= 37.5 && minutes < 52.5) {
    return parseFloat(splitByDec[0]?.concat('.75'))
  }

  return ((parseFloat(splitByDec[0]?.concat('.00'))) + 1)
}

export const getHoursAndMinJustNumbers = ((milliseconds: number) => {
  if (!milliseconds) {
    return ':'
  }
  const time = moment.duration(milliseconds)
  const hours = time.days() === 0 && time.hours() === 0 ? '' : time.days() * 24 + time.hours()
  const minutes = time.minutes() === 0 ? '' : time.minutes()
  return (`${hours}:${minutes}`)
})
