import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import {
  Button, Collapse, ListItem, Theme
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const PREFIX = 'NavItem';

const classes = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  button: `${PREFIX}-button`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  active: `${PREFIX}-active`
};

const StyledListItem = styled(ListItem)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.item}`]: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.itemLeaf}`]: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },

  [`& .${classes.button}`]: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },

  [`& .${classes.buttonLeaf}`]: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular as number,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium as number
      }
    }
  },

  [`& .${classes.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    marginRight: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    marginRight: 'auto',
    color: 'white'
  },

  [`& .${classes.active}`]: {
    color: theme.palette.secondary.main,
    backgroundColor: '#7F56D9',
    borderRadius: '8px',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: theme.palette.secondary.main
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

interface NavItemProps {
  children?: ReactNode,
  className?: string,
  depth: number,
  href?: string,
  icon?: any,
  info?: any,
  open?: boolean,
  title: string,
  onClick?: () => void
}

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  onClick,
  ...rest
}: NavItemProps) => {
  const [open, setOpen] = useState<boolean>(openProp)

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = {
    paddingLeft
  }

  if (children) {
    return (
      <StyledListItem sx={{ pt: 0, pb: 0 }} className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <div>
          <Button className={classes.button} onClick={handleToggle} style={style}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Collapse in={open}>
            <div>
              {children}
            </div>
          </Collapse>
        </div>
      </StyledListItem>
    );
  }

  const activeClassProp = href ? { activeClassName: classes.active } : {}
  return (
    <StyledListItem sx={{ pt: 0, pb: 0 }} className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        {...activeClassProp}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={href ? RouterLink : 'button'}
        exact
        style={style}
        to={href}
        onClick={() => onClick && onClick()}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </StyledListItem>
  )
}

export default NavItem
