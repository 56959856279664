import * as R from 'ramda'

export const getInitials = (name: string = ''): string => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('')

type TrucateOptions = { length?: number, omission?: string }
export const truncate = (str: string, options?: TrucateOptions) => {
  const length = options?.length || 30
  const omission = options?.omission || '...'
  return str.length > length ? `${str.substring(0, length)}${omission}` : str
}

export const camelCaseToSentenceCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const displayGeolocation = (longitude: number, latitude: number) => {
  if (!longitude || !latitude) return ''

  const longDirection = longitude > 0 ? 'N' : 'S'
  const latDirection = latitude > 0 ? 'E' : 'W'

  return `${Math.abs(longitude)}° ${longDirection}, ${Math.abs(latitude)}° ${latDirection}`
}
const getEndOfLineData = (obj) => {
  const collectValues = (acc, value) => {
    if (R.is(Object, value) && !R.is(Array, value)) {
      // Recursively process if the value is an object (but not an array)
      return R.concat(acc, getEndOfLineData(value));
    }
    return R.append(value, acc);
  };

  return R.reduce(collectValues, [], R.values(obj));
}

export const hasMeaningfulValue = (data) => {
  const endOfLineData = getEndOfLineData(data);
  // Filter out undefined and empty arrays
  const filteredData = R.filter(
    (value) => value !== undefined && !(Array.isArray(value) && value.length === 0),
    endOfLineData
  );
  // Check if there are any meaningful values
  return filteredData.length > 0;
}
