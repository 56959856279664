import {
  ApolloClient, InMemoryCache, createHttpLink, NormalizedCacheObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getToken } from 'src/utils/auth'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient as createWsClient } from 'graphql-ws';
import typeDefs from './typeDefs'

const uri = process.env.NODE_ENV !== 'production' ? 'http://localhost:5001/graphql' : process.env.REACT_APP_API_URL
const wsUri = process.env.NODE_ENV !== 'production' ? 'ws://localhost:5001/graphql' : `wss://${process.env.REACT_APP_API_URL}`.replace('https://', '')
const httpLink = createHttpLink({
  uri
})

const wsClient = createWsClient({
  url: wsUri,
})

const wsLink = new GraphQLWsLink(
  wsClient
);

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    'x-token': token,
    headers: {
      ...headers,
      'x-token': token || ''
    }
  }
})

let client: ApolloClient<NormalizedCacheObject>

export const createClient = () => {
  const cache = new InMemoryCache()
  const splitLink = ({ query }: any) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  };
  client = new ApolloClient({
    cache,
    link: authLink.split(splitLink,
      wsLink,
      httpLink),
    connectToDevTools: process.env.NODE_ENV !== 'production',
    typeDefs
  })

  wsClient.on('connected', (event) => {
    console.log('WebSocket connection opened:', event);
  });

  wsClient.on('error', (error) => {
    console.log('WebSocket error:', error);
  });

  return client
}
