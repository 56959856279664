import { gql } from '@apollo/client'

export const INVOICES = gql`
    query($data: InvoiceQuery) {
        invoices(data: $data){
            id
            name
            billedAt
            createdAt
            clientId
        }
    }
`

export const INVOICE = gql`
    query($id: ID) {
        invoice(id: $id){
            id
            name
            billedAt
            createdAt
            timeEntries {
                id
                date
                clockIn
                clockOut
                duration
                note
                user {
                    firstName
                    lastName
                }
            }
            client {
                id
                name
            }
        }
    }
`

export const CREATE_INVOICE = gql`
    mutation($data: CreateInvoiceInput!) {
        createInvoice(data: $data) {
            id
            name
            billedAt
            createdAt
            clientId
        }
    }
`
