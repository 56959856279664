import React, { useState } from 'react'
import type { ReactNode } from 'react'
import {
  Box, useMediaQuery, Theme
} from '@mui/material'
import NavBar from './NavBar'
import TopBar from './TopBar'

interface DashboardLayoutProps {
  children?: ReactNode
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false)
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <Box sx={{
      display: 'flex',
      overflow: 'hidden',
      width: '100%'
    }}
    >
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

      <Box sx={{
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingLeft: lgUp ? '256px' : 0,
      }}
      >
        <Box display="flex" flex="1 1 auto" overflow="hidden">
          <Box flex="1 1 auto" height="100%" overflow="hidden">
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout
