import { gql } from '@apollo/client'

export const CAREGIVERS = gql`
  query($data: CaregiverQuery!) {
    caregivers(data: $data) {
      count
      results {
        id
        email
        profilePicPath
        sfContact {
          firstName
          lastName
          id
          externalId
          phone
          paStatus
          paType
          caregiverType
          employeeNumber
          sfAccountContactRelation {
            allowClockIn
            sfAccount {
                firstName
                lastName
                id
            }
          }
        }
      }
    }
  }
`

export const RESET_CAREGIVER_PASSWORD = gql`
  mutation($caregiverId: ID!, $password: String!) {
    resetCaregiverPassword(caregiverId: $caregiverId, password: $password) {
        id
        firstName
        lastName
        email
        sfContact {
          paStatus
          paType
          caregiverType
          createdDate
          sfAccountContactRelation {
            allowClockIn
            sfAccount {
                firstName
                lastName
                id
            }
          }
        }
    }
  }
`
