import React, {
  Suspense,
  Fragment,
  lazy
} from 'react'
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import AuthLayout from 'src/layouts/AuthLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'

type Routes = {
  exact?: boolean,
  path?: string | string[],
  guard?: any,
  layout?: any,
  component?: any,
  routes?: Routes
}[]

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/error/NotFoundView'))
  },
  {
    path: '/auth',
    guard: GuestGuard,
    layout: AuthLayout,
    routes: [{
      exact: true,
      path: '/auth/login',
      component: lazy(() => import('src/views/login'))
    },
    {
      exact: true,
      path: '/auth/resetpassword',
      component: lazy(() => import('src/views/resetPassword'))
    }]
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/utilizationReport'))
      },
      {
        exact: true,
        path: '/employees',
        component: lazy(() => import('src/views/employeeList'))
      },
      {
        exact: true,
        path: '/employee/:id/:tab?',
        component: lazy(() => import('src/views/employeeProfile'))
      },
      {
        exact: true,
        path: '/errors/error-401',
        component: lazy(() => import('src/views/error/NotFoundView'))
      },
      {
        exact: true,
        path: '/patients',
        component: lazy(() => import('src/views/patientList'))
      },
      {
        exact: true,
        path: '/patient/:id/:tab?',
        component: lazy(() => import('src/views/patientDetails'))
      },
      {
        exact: true,
        path: '/caregivers',
        component: lazy(() => import('src/views/caregiverList'))
      },
      {
        exact: true,
        path: '/caregiver/:id/:tab?',
        component: lazy(() => import('src/views/caregiverDetails'))
      },
      {
        exact: true,
        path: '/clock/:id',
        component: lazy(() => import('src/views/clock'))
      },
      {
        exact: true,
        path: '/chat/:id?',
        component: lazy(() => import('src/views/chat'))
      },
      {
        exact: true,
        path: '/rulegroups',
        component: lazy(() => import('src/views/ruleGroupList'))
      },
      {
        exact: true,
        path: '/rulegroup/:id',
        component: lazy(() => import('src/views/ruleGroupDetails'))
      },
      {
        exact: true,
        path: '/requests/:tab?',
        component: lazy(() => import('src/views/requests'))
      },
      {
        exact: true,
        path: '/timeAndAttendance',
        component: lazy(() => import('src/views/mltcDetails'))
      },
      {
        exact: true,
        path: '/payrolls',
        component: lazy(() => import('src/views/payrollList'))
      },
      {
        exact: true,
        path: '/payroll/add',
        component: lazy(() => import('src/views/addPayroll'))
      },
      {
        exact: true,
        path: '/payroll/:id',
        component: lazy(() => import('src/views/payrollDetails'))
      },
      {
        exact: true,
        path: '/claims/generate',
        component: lazy(() => import('src/views/generateClaims'))
      },
      {
        exact: true,
        path: '/claims/:id?',
        component: lazy(() => import('src/views/claims'))
      },
      {
        exact: true,
        path: '/settings/:tab?',
        component: lazy(() => import('src/views/settings'))
      },
      {
        exact: true,
        path: '/chatsettings',
        component: lazy(() => import('src/views/chatSettings'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
]

export default routes
