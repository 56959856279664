import { gql } from '@apollo/client'

export const PAYROLL_BY_ID = gql`
  query($id: String!) {
    payrollById(id: $id){
        id
    createdAt
    updatedAt
    payDate
    payPeriodStartDate
    payPeriodEndDate
    payrollStatus
    type
    sentToViventium
    payrollDetails{
      id
      sentToFingerCheck
      hoursPaid
      clockSfid
      payrate
      clock{
      id
      payrollDetails {
        id
      }
      caregiver{
        id
        firstName
        lastName
        sfContactId
        sfContact{
          id
          sfid
          employeeNumber
          caregiverType
          area
          jobPunched {
            sfExternalId
          }
          sfAccountContactRelation{
            payrate
          }
          SFCaregiverPayrate{
            paEffectiveDate
            hhaEffectiveDate
            paPayrate
            hhaPayrate
          }
        }
      }
      clockIn
      clockOut
      patient{
        id
        sfExternalId
        firstName
        lastName
        county
        sfAuthorizations {
          authStartDate
          authEndDate
          county
        }
      }
    }
      compTime{
        id
        date
        hours
        caregiver{
          id
          firstName
          lastName
          sfContactId
          sfContact{
            id
            caregiverType
            employeeNumber
            jobPunched {
              sfExternalId
            }
            sfAccountContactRelation{
              payrate
            }
            SFCaregiverPayrate{
              paEffectiveDate
              hhaEffectiveDate
              paPayrate
              hhaPayrate
            }
        }
        }
      }
    }
  }
}
`

export const PAYROLLS = gql`
    query($payDate: Date, $payrollStatus: String, $type: String, $skip: Int, $take: Int) {
        payrolls(payDate: $payDate, payrollStatus: $payrollStatus, type: $type, skip: $skip, take: $take) {
          count
          results {
            createdBy {
              id
              firstName
              lastName
            }
              id
              createdAt
              payDate
              type
              payrollStatus
              payPeriodStartDate
              payPeriodEndDate
              sentToViventium
          }
      }
  }
  `

export const CREATE_PAYROLL = gql`
  mutation($data: CreatePayrollInput!) {
    createPayroll(data: $data){
    id
  }
}
`
export const DELETE_PAYROLL = gql`
  mutation($id: String!){
    deletePayroll(id: $id){
      id
    }
  }
`

export const SEND_PAYROLL_TO_VIVENTIUM = gql`
  mutation($id: String!){
    uploadPayrollToViventium(id: $id)
  }
`
export const PAYROLL_STATUS_LIST = gql`
    query {
      payrollStatuses
      }
`

export const PAYROLL_TYPES_LIST = gql`
    query {
      payrollTypes
      }
`

export const SEND_PAYROLL_CLOCKS_TO_FINGERCHECK = gql`
     mutation($payrollId: String!) {
       sendPayrollClocksToFingerCheck(payrollId: $payrollId){
    id
    createdAt
    updatedAt
    payDate
    payPeriodStartDate
    payPeriodEndDate
    payrollStatus
    type
    payrollDetails{
      id
      hoursPaid
      clockSfid
      clock{
      id
      caregiver{
        id
        firstName
        lastName
        sfContactId
        sfContact{
          id
          employeeNumber
          jobPunched{
            id
          }
        }
      }
      clockIn
      clockOut
    }
      compTime{
        id
        date
        hours
        caregiver{
          id
          firstName
          lastName
          sfContactId
          sfContact{
            id
            employeeNumber
            jobPunched{
              id
            }
        }
        }
      }
    }
  }
}
 `

export const MARK_AS_SENT_TO_FINGERCHECK = gql`
  mutation ($id: String!) {
    markAsSentToFingercheck(id: $id) {
      id
    }
  }
`

export const GET_PTO_BY_EMPLOYEE_NUMBER = gql`
  query($employeeNumber: String!, $effectiveDate: Date!) {
    ptoByEmployeeNumber(employeeNumber: $employeeNumber, effectiveDate: $effectiveDate) {
      endDate
      startDate
      pendingAmount
      takenAmount
      allowedAmount
    }
  }
`
