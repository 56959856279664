import { gql } from '@apollo/client'

export const AUDIT_LOGS = gql`
query auditLogs($tableNames: [String]!, $rowIds: [String]!, $actions: [AuditLogActions!], $skip: Int, $take: Int){
    auditLogs(table_names: $tableNames, row_ids: $rowIds, actions: $actions, skip: $skip, take: $take){
        count
        results{
            id
            table_name
            row_id
            user_id
            firstName
            lastName    
            old_fields
            new_fields
            action
            created_at
        }
    }
}
`
