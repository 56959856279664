import { gql } from '@apollo/client'

export const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      refreshToken
      user {
        id
        email
        role
        firstName
        lastName
        sfUserId
      }
    }
  }
`

export const LOGOUT = gql`
  mutation($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`

export const FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    forgotPassword(email: $email)
  }
`

export const VALIDATE_RESET = gql`
  mutation($email: String!, $passwordReset: String!) {
    isResetPasswordValid(email: $email, passwordReset: $passwordReset)
  }
`

export const RESET_PASSWORD = gql`
  mutation($email: String!, $passwordReset: String!, $password: String!) {
    resetPassword(email: $email, password: $password, passwordReset: $passwordReset)
  }
`

export const QUERY_AUTH = gql`
  query saveAuth {
    auth {
      isAuthenticated
      id
      email
      role
    }
  }
`

export const QUERY_GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      role
      email
      firstName
      lastName
      sfUserId
      sfUser {
        id
        sfid
      }
      settings {
        mltcDetails {
          exceptions
          coordinatorId
          mltcId
          startDate
          endDate
          status
          clockStatus
        }
        tableFilters {
          table
          hidden_columns
          filters{
            id
            name
            isActive
            options {
              key
              value
            }
          }
        }
      }
    }
  }
`
export const QUERY_GET_CURRENT_USER_FOR_SETTINGS = gql`
  query getCurrentUserSettings {
    getCurrentUser {
      id
      settings {
        mltcDetails {
          exceptions
          coordinatorId
          mltcId
          startDate
          endDate
          status
          clockStatus
        }
        tableFilters {
          table
          hidden_columns
          filters{
            id
            name
            isActive
            options {
              key
              value
            }
          }
        }
      }
    }
  }
`

export const CG_SIGN_UP = gql`
    mutation($data: SignUpInput!) {
        cgSignUp(data: $data) {
            id
            email
            phone
        }
    }
`

export const GENERATE_REGISTRATION_CODE = gql`
    mutation($phone: String!, $method: String!) {
        cgGenerateRegistrationCode(phone: $phone, method: $method)
    }
`
