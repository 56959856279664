import moment from 'moment'

export const getDigitalTime = (seconds?: boolean) => moment().format(`h:mm${seconds ? ':ss' : ''} a`)

export const msInHr = 3600000

export const getTimeElapsed = ({ datetime }: { datetime: string }) => {
  const now = moment().utc()
  const then = moment(datetime).utc()

  const ms = moment(now).diff(moment(then))
  const d = moment.duration(ms)

  if (Math.floor(d.asHours()) < 0) {
    return {
      hours: '0',
      minutes: '00',
      seconds: '00'
    }
  }

  return {
    hours: Math.floor(d.asHours()),
    minutes: moment.utc(ms).format('mm'),
    seconds: moment.utc(ms).format('ss')
  }
}

export const getDurationDifference = ({
  dateFrom,
  dateTo
}: {
  dateFrom: moment.Moment,
  dateTo: moment.Moment
}) => {
  const d1 = moment(dateTo)
  const d2 = moment(dateFrom)

  const ms = moment(d1, 'DD/MM/YYYY HH:mm:ss').diff(moment(d2, 'DD/MM/YYYY HH:mm:ss'))
  const d = moment.duration(ms)
  return {
    hours: Math.floor(d.asHours()),
    minutes: moment.utc(ms).format('mm'),
    seconds: moment.utc(ms).format('ss')
  }
}
