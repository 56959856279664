import React, {
  useState,
  useRef
} from 'react';
import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import { capitalCase } from 'change-case';
import {
  // Badge,
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Theme
} from '@mui/material';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/theme/constants';

const PREFIX = 'Settings';

const classes = {
  badge: `${PREFIX}-badge`,
  popover: `${PREFIX}-popover`,
  icon: `${PREFIX}-icon`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.badge}`]: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },

  [`& .${classes.popover}`]: {
    width: 320,
    padding: theme.spacing(2)
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

const Settings: FC = () => {
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <Root>
      <Tooltip title="Settings">
        {/* <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        > */}
        <IconButton color="inherit" onClick={handleOpen} ref={ref} size="large">
          <SvgIcon className={classes.icon} fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
        {/* </Badge> */}
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        className={classes.popover}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Settings
        </Typography>
        <Box
          mt={2}
          px={1}
        >
          {/* <FormControlLabel
          control={(
            <Switch
              checked={values.direction === 'rtl'}
              edge="start"
              name="direction"
              onChange={(event) => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
            />
          )}
          label="RTL"
        /> */}
        </Box>
        {/* <Box
        mt={2}
        px={1}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.responsiveFontSizes}
              edge="start"
              name="direction"
              onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
            />
          )}
          label="Responsive font sizes"
        />
      </Box> */}
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
      </Popover>
    </Root>
  );
}

export default Settings;
