import decodeJwt from 'jwt-decode'
import moment from 'moment'

export const TOKEN = 'token'
export const REFRESH_TOKEN = 'refreshtoken'

export const setTokens = (token: string, refreshToken: string) => {
  localStorage.setItem(TOKEN, token)
  localStorage.setItem('refreshtoken', refreshToken)
}

export const getToken = () => localStorage.getItem(TOKEN)

interface decodeJwtResult {
  exp: number
}

export const isLoggedIn = () => {
  // const token = getSecureStorage('dca-t')
  const refreshToken = localStorage.getItem(TOKEN)
  const time = moment().unix()
  // if (!token || !refreshToken)
  if (!refreshToken) {
    return false
  }

  // const decodedToken = decodeJwt(token) as any
  const decodedRefreshToken = decodeJwt<decodeJwtResult>(refreshToken)

  // const tokenTime = moment(decodedToken.exp).utc()

  if (time > decodedRefreshToken.exp) return false

  return true
}
