import { gql } from '@apollo/client'

export const CLOCK_DATE_RANGE = gql`
query($statuses: [ClockStatus!]!) {
  getClocksDateRange(statuses: $statuses) {
    startDate
    endDate
  }
}
`

export const CLOCKS = gql`
query getCaregiverClocks($data: ClocksQuery!) {
    clocks(data: $data) {
        id
        clockIn
        clockOut
        note
        selfiePath
        selfieClockOutPath
        status
        estimatedPay
        flags {
          status
          note
          reason
          exceededBy
          restrictionName
          restrictionMessage
          restrictionValue
          unit
          approvalHoursPay
          rejectedHoursPay
          statusUpdatedById
          statusUpdatedBy {
            id
            firstName
            lastName
          }
        }
        editClockRequest{
          id
          status
          oldClockIn
          oldClockOut
          requestedClockIn
          requestedClockOut
          oldPatientId
          requestedPatientId
          note
        }
        geolocation {
          longitude
          latitude
          altitude
          timestamp
        }
        clockOutGeolocation {
          longitude
          latitude
          altitude
          timestamp
        }
        patient {
            firstName
            lastName
            id
            sfExternalId
            sfAuthorizations{
              id
              hoursPerWeek
              daysPerWeek
              hoursPerDay
              liveIn
              authStartDate
            }

        }
        caregiver {
            id
            email
            firstName
            lastName
            sfContact{
              id
              employeeNumber
              firstName
              lastName
            }
        }
    }
  }
`

export const CLOCKS_BY_IDS = gql`
  query($ids: [String]!) {
      clocksByIds(ids: $ids) {
          id
          clockIn
          clockOut
          note
          selfiePath
          selfieClockOutPath
          status
          estimatedPay
          flags {
            id
            status
            restrictionName
          }
          patient {
            id
          }
      }
  }
`

export const CLOCKS_BY_DATE = gql`
  query($dateFrom: Date!, $dateTo: Date!) {
      clocksByDate(dateFrom: $dateFrom, dateTo: $dateTo) {
          id
          clockIn
          clockOut
          note
          selfiePath
          selfieClockOutPath
          status
          sFPatientId
      }
  }
`

export const CLOCKS_FOR_PAYROLL = gql`
  query($data: ClockPayrollInput!) {
      clocksForPayroll(data: $data) {
         id
         hoursToBePaid
         hoursWorked
         status
         caregiverId
         clockIn
         clockOut
         estimatedPay
         flags {
          status
          id
          approvalHoursPay
          rejectedHoursPay
         }
      }
  }
`

export const UPDATE_CLOCK = gql`
  mutation($data: UpdateClockInput!){
    updateClock(data: $data){
      id
      clockIn
      clockOut
      note
      status
      patient{
        id
      }
      caregiver{
        id
      }
    }
  }
`

export const CREATE_CLOCK = gql`
  mutation($data: CreateClockInput!){
    createClock(data: $data){
      id
      clockIn
      clockOut
      note
      status
      patient{
        id
      }
      caregiver{
        id
      }
    }
  }
`

export const UPDATE_CLOCK_STATUS = gql`
  mutation($data: ChangeStatusClockInput!){
    updateClockStatus(data: $data){
      id
      note
      status
    }
  }
`

export const GENERATE_VIEW_IMAGE_LINK = gql`
  mutation {
    generateViewImageLink {
      containerUrl
      sasQuery
    }
  }
`

export const RESOLVE_CLOCK_FLAG = gql`
  mutation($clockId: ID!, $resolution: ClockFlagResolution) {
    resolveClockFlag(clockId: $clockId, resolution: $resolution) {
      id
      estimatedPay
      flags{
        status
        note
        reason
        restrictionName
      }
    }
  }
`
export const RESOLVE_MANY_CLOCKS_FLAGS = gql`
  mutation($data: [resolveManyFlags!]!) {
    resolveManyClocksFlags(data: $data) {
      id
      estimatedPay
      flags{
        id
        status
        note
        reason
        restrictionName
      }
    }
  }
`

export const CLOCK = gql`
query($id: ID!) {
    clock(id: $id) {
        id
        clockIn
        clockOut
        selfiePath
        note
        selfieClockOutPath
        status
        estimatedPay
        createdWith
        notes{
          id
          clockId
          note
          date
          createdBy {
            id
            firstName
            lastName
          }
        }
        flags {
          status
          note
          reason
          id
          exceededBy
          restrictionMessage
          restrictionName
          restrictionValue
          unit
          approvalHoursPay
          rejectedHoursPay
          statusUpdatedById
          statusUpdatedAt
        }
        createdBy {
            id
            firstName
            lastName
          }
        editClockRequest{
          id
          status
          oldClockIn
          oldClockOut
          requestedClockIn
          requestedClockOut
          oldPatientId
          requestedPatientId
          note
        }
        geolocation {
          longitude
          latitude
          altitude
          timestamp
        }
        clockOutGeolocation {
          longitude
          latitude
          altitude
          timestamp
        }
        payrollDetails{
          id
          hoursPaid
        }
        claimDetails {
          id
          authorizedHours
        }
        patient {
            firstName
            lastName
            id
            shippingStreet
            shippingCity
            shippingState
            shippingPostalCode
            sfAuthorizations{
              id
              hoursPerWeek
              daysPerWeek
              hoursPerDay
            }

        }
        caregiver {
            id
            email
            firstName
            lastName
            sfContact{
              id
              firstName
              lastName
            }
        }
        patientSignature {
          signature
        }
        caregiverSignature {
          signature
        }
    }
  }
  `

export const CLOCKS_WITHOUT_FLAGS = gql`
  query($data: ClockTotalsInput! ){
    clocksWithoutFlags(data: $data){
      status
      clockIn
      clockOut
      id
    }
}
`

export const DELETE_CLOCK = gql`
mutation($id: ID!){
  deleteClock(id: $id)
}
`

export const AUTHS_FOR_WEEK = gql`
query($data: AuthsForWeekQuery!){
  authsForWeek(data: $data){
    id
    hoursPerWeek
  }
}
`
