import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Divider,
  Theme
} from '@mui/material'
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather'
import { useQuery } from '@apollo/client'
import { GLOBAL_SEARCH } from 'src/apollo/queries'
import * as R from 'ramda'

const PREFIX = 'Search';

const classes = {
  drawer: `${PREFIX}-drawer`,
  icon: `${PREFIX}-icon`,
  addColor: `${PREFIX}-addColor`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`& .${classes.drawer}`]: {
    width: 500,
    maxWidth: '100%'
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.addColor}`]: {
    color: theme.palette.primary.main
  }
}));

const Search: FC = () => {
  const [value, setValue] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [isOpen, setOpen] = useState<boolean>(false)

  const { data, error, loading } = useQuery(GLOBAL_SEARCH, {
    variables: { search },
    skip: search === '' || !search
  })

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <Root>
      <Tooltip title="Search">
        <IconButton className={classes.icon} onClick={handleOpen} size="large">
          <SvgIcon fontSize="small">
            <SearchIcon className={classes.addColor} />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{
          paper: classes.drawer
        }}
        ModalProps={{
          BackdropProps: {
            invisible: true
          }
        }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar
          options={{
            suppressScrollX: true
          }}
        >
          <Box p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" color="textPrimary">
                Search
              </Typography>
              <IconButton onClick={handleClose} size="large">
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                placeholder="Search users, patients, and caregivers"
                value={value}
                variant="outlined"
                onKeyPress={(e) => e.key === 'Enter' && setSearch(value)}
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button color="secondary" variant="contained" onClick={() => setSearch(value)}>
                Search
              </Button>
            </Box>
            <Box mt={4}>
              {loading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {data?.search && R.remove(0, 1, Object.keys(data?.search)).map((type) => data?.search[type]?.map((result, i) => (
                    <>
                      <Box key={i} mb={1} mt={1}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Link variant="h6" color="textPrimary" component={RouterLink} to={'/'.concat(type === 'users' ? 'employee/' : type.replace(/s$/, '/')).concat(result.id)}>
                              {result.firstName}
                              {' '}
                              {result.lastName}
                            </Link>
                          </Box>
                          <Box>
                            <Typography variant="caption" style={{ fontStyle: 'italic' }}>{type.replace(/s$/, '')}</Typography>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography variant="caption" color="textSecondary">
                              {type === 'patients' ? result.shippingStreet : result.email}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption" color="textSecondary">
                              {type === 'patients' ? result.shippingCity?.concat(', ').concat(result.shippingState) : result.phone}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  )))}
                  {data?.search && (
                    <Box mt={3}>
                      <Typography variant="caption" style={{ fontStyle: 'italic' }}>Refine your search to see more results.</Typography>
                    </Box>
                  )}
                  {error && (
                    <Box mt={3}>
                      <Typography variant="caption" style={{ fontStyle: 'italic' }}>Something went wrong searching. Please try again.</Typography>
                      <Typography variant="caption" style={{ fontStyle: 'italic' }}>{error}</Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </Root>
  );
}

export default Search
