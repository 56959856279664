import React from 'react'
import { format } from 'date-fns'
import { Divider, Grid, TextField } from '@mui/material'
import { Autocomplete } from '@mui/lab'

interface PtoListProps {
  compTimesToCreate: any[]
  updateComptTimesToCreate: Function
}

const PTOList = ({ compTimesToCreate, updateComptTimesToCreate }: PtoListProps) => (
  <>
    {compTimesToCreate.map((ct, i) => (
      <React.Fragment key={ct}>
        <Grid container style={{ marginTop: '8px' }}>
          <Grid item xs={3}>
            <div>Name:</div>
            {`${ct.selectedCg.sfContact.firstName} ${ct.selectedCg.sfContact.lastName}`}
          </Grid>
          <Grid item xs={2}>
            <div>Date:</div>
            {format(ct.date, 'MM/dd/yy')}
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              clearIcon={null}
              id="hours"
              size="small"
              value={ct.hours}
              style={{ width: '5px' }}
              onChange={(e, v) => updateComptTimesToCreate({ index: i, value: { ...ct, hours: v } })}
              options={Array.from(Array(12).keys()).map((i) => (i + 1).toString())}
              renderInput={(params) => <TextField {...params} label="Hours" variant="outlined" />}
            />

          </Grid>
          <Grid item xs={2}>

            <Autocomplete
              clearIcon={null}
              style={{ width: '5px' }}
              id="minutes"
              size="small"
              value={ct.minutes}
              onChange={(e, v) => updateComptTimesToCreate({ index: i, value: { ...ct, minutes: v } })}
              options={['00', '15', '30', '45']}
              renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <div>Note:</div>
            {ct.note}
          </Grid>
          <Grid item xs={2}>{ct.isJuryDuty ? 'Jury Duty' : ''}</Grid>
        </Grid>
        <Divider />
      </React.Fragment>
    ))}
  </>
)

export default PTOList
