import { gql } from '@apollo/client'

export const MLTCS = gql`
query($data: WeekOfClocksInput) {
    sfMltcs(data: $data) {
      name
      id
        Clock{
          estimatedPay
          id
          clockIn
          clockOut
          selfieClockOutPath
          selfiePath
          geolocation {
          longitude
          latitude
          altitude
          timestamp
        }
        clockOutGeolocation {
          longitude
          latitude
          altitude
          timestamp
        }
          editClockRequest{
            status
          }
          flags{
            status
            note
            reason
            exceededBy
            restrictionMessage
            restrictionName
            restrictionValue
            unit
          }
        }
    }
  }
  `

export const MLTC_NAMES = gql`
    query($forClaims: Boolean, $startDate:Date, $endDate:Date) {
      mltcs(forClaims: $forClaims, startDate: $startDate, endDate: $endDate) {
          id
          name
          sfid
          clockForClaimCount
        }
    }
`

export const MLTC_CAREGIVER_ERRORS = gql`
    query($data: MltcInputQuery!) {
        sfMltcCaregiverErrors(data: $data) {
            mltc {
                id
                sfid
                name
            }
            count,
            caregivers {
                id
                firstName
                lastName
                address
                email
                sfContact{
                  employeeNumber 
                  firstName
                  lastName 
                }
                sfContactId
                Clock {
                    id
                    createdAt
                    clockIn
                    clockOut
                    note
                    selfiePath
                    selfieClockOutPath
                    status
                    geolocation {
                        longitude
                        latitude
                    }
                    clockOutGeolocation {
                        longitude
                        latitude
                    }
                    estimatedPay
                    flags {
                      exceededBy
                      restrictionMessage
                      restrictionName
                      restrictionValue
                      unit
                      approvalHoursPay
                      rejectedHoursPay
                      status
                      note
                      reason
                    }
                    patient {
                        firstName
                        lastName
                        id
                        sfExternalId
                    }
                }
            }
        }
    }
`
