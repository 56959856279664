import { mergeAll } from 'ramda'
import {
  colors, responsiveFontSizes
} from '@mui/material'
import createMuiTheme from '@mui/material/styles/createTheme'
import type { Shadows as MuiShadows } from '@mui/material/styles/shadows'
import type {
  Palette as MuiPalette
} from '@mui/material/styles/createPalette'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { THEMES } from './constants'
import { softShadows, strongShadows } from './shadows'
import typography from './typography'

export interface Theme {
  palette: MuiPalette
}

type Direction = 'ltr' | 'rtl'

interface ThemeConfig {
  direction?: Direction,
  responsiveFontSizes?: boolean,
  theme?: string
}

interface DeprecatedThemeOptions {
  name?: string,
  direction?: Direction,
  typography?: Record<string, any>,
  overrides?: Record<string, any>,
  palette?: Record<string, any>,
  shadows?: MuiShadows
}

const baseOptions: DeprecatedThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
}

const themesOptions: DeprecatedThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.grey[100],
        dark: '#f4f6f8',
        paper: colors.common.white,
        light: '#fcfcfd'
      },
      primary: {
        main: '#7F56D9'
      },
      secondary: {
        main: '#6642b5'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        light: '#475467'
      },
      gray: {
        100: '#f2f4f7',
        200: '#eaecf0'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.15)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#1C2025',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#7F56D9'
      },
      secondary: {
        main: '#6642b5'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      },
      gray: {
        100: '#2a2a2a',
        200: '#525252'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.15)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#222431',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      },
      gray: {
        100: '#2a2a2a',
        200: '#525252'
      }
    },
    shadows: strongShadows
  }
]

export const createTheme = (config: ThemeConfig = {
}): Theme => {
  const themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
  }

  let theme = createMuiTheme(
    mergeAll([
      baseOptions,
      themeOptions,
      {
        direction: config.direction
      }
    ])
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
