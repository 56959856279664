import React from 'react'
import moment from 'moment'
import {
  Box, TextField, InputAdornment, IconButton, Dialog, Button
} from '@mui/material'
import { KeyboardArrowDown, Clear } from '@mui/icons-material'
import { DateRange } from 'react-date-range';

type IPropsDateRange = {
  setSelectionRange: Function,
  selectionRange: any,
  allowClearable?: boolean,
  margin?: 'none' | 'dense' | 'normal',
  label?: string,
  onTimePeriodChange?: Function
  timePeriodOptions: any[],
  style?: Object,
  placeholder?: string,
  helperText?: string,
  error?: boolean
}

const DateRangeComponent = (props: IPropsDateRange) => {
  const {
    setSelectionRange, selectionRange, allowClearable,
    margin = 'dense', label, onTimePeriodChange, timePeriodOptions, style = {},
    placeholder, helperText, error
  } = props
  const [open, setOpen] = React.useState<boolean>(false)

  const [newSelectionRange, setNewSelectionRange] = React.useState<any>(selectionRange)

  const applyChanges = () => {
    setSelectionRange(newSelectionRange)
    setOpen(false)
  }

  const clearSelection = () => {
    setSelectionRange({ startDate: null, endDate: null, key: 'selection' })
  }

  return (
    <>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        maxWidth="md"
      >
        <Box padding={3} display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column">
            <DateRange
              ranges={[newSelectionRange]}
              direction="horizontal"
              months={1}
              moveRangeOnFirstSelection={false}
              editableDateInputs
              dateDisplayFormat="MM/dd/yyyy"
              onChange={(v) => {
                setNewSelectionRange(v.selection || v.range1)
              }}
            />
            <Button onClick={applyChanges} variant="contained" style={{ backgroundColor: 'rgb(61, 145, 255)', width: '50%', alignSelf: 'flex-end' }}>Apply</Button>
          </Box>
          {onTimePeriodChange
            && (
              <Box width="200px" display="flex" flexDirection="column" marginTop={5} marginLeft={5}>
                {
                  timePeriodOptions?.map((t) => (
                    <Button
                      variant="outlined"
                      style={{ marginBottom: 15, height: 75 }}
                      onClick={() => {
                        onTimePeriodChange(t.value, t)
                        setNewSelectionRange(t)
                        setOpen(false)
                      }}
                    >
                      {t.label}
                    </Button>
                  ))
                }
              </Box>
            )}
        </Box>
      </Dialog>
      <TextField
        label={label || ''}
        variant="outlined"
        error={error}
        size="small"
        margin={margin}
        helperText={helperText || ''}
        style={{ minWidth: 275, ...style }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(true)} size="large">
                <KeyboardArrowDown />
              </IconButton>
              {allowClearable && selectionRange?.startDate && (
                <IconButton onClick={clearSelection} size="large">
                  <Clear />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          if (!allowClearable) return
          const pts = e.target.value.split('-')
          setNewSelectionRange({ startDate: !pts[0] || !Date.parse(pts[0]?.toString()) ? undefined : new Date(pts[0]), endDate: !pts[1] || !Date.parse(pts[1]?.toString()) ? undefined : new Date(pts[1]), key: 'selection' })
        }}
        value={placeholder && (!selectionRange || (!selectionRange.startDate && !selectionRange.endDate))
          ? placeholder : `${selectionRange?.startDate ? moment(selectionRange.startDate)?.format('M/DD/YYYY') : 'Early'} - ${selectionRange?.endDate ? moment(selectionRange.endDate)?.format('M/DD/YYYY') : 'Continuous'}`}
      />
    </>
  )
}

export default DateRangeComponent
