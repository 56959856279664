import { useQuery, useSubscription } from '@apollo/client';
import { Chat } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UNREAD_MESSAGE_COUNT, CHAT_UPDATED } from 'src/apollo/queries';

const PREFIX = 'ChatIcon';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  badge: `${PREFIX}-badge`
};

const Root = styled(IconButton)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    margin: '4px',
    cursor: 'pointer'
  },
  [`& .${classes.icon}`]: {
    alignSelf: 'center',
    color: theme.palette.primary.main
  }
}));

const ChatIcon = () => {
  const history = useHistory()
  const { data, refetch } = useQuery(UNREAD_MESSAGE_COUNT)

  const { data: chatUpdated } = useSubscription(CHAT_UPDATED);

  useEffect(() => {
    const loadResult = async () => {
      if (chatUpdated) {
        const result = await refetch()
        if (result?.data?.unreadMessageCount > data?.unreadMessageCount) {
          const audio = new Audio(`${process.env.PUBLIC_URL}/static/sounds/notification.mp3`);
          audio.play();
        }
      }
    }
    loadResult()
  }, [chatUpdated]);

  return (
    <Root
      className={classes.root}
      onClick={() => {
        history.push('/chat')
      }}
    >
      {data?.unreadMessageCount ? (
        <Badge badgeContent={data?.unreadMessageCount} color="error">
          <Chat className={classes.icon} />
        </Badge>
      ) : <Chat className={classes.icon} />}
    </Root>
  );
}

export default ChatIcon;
