import { gql } from '@apollo/client'

export const MLTCS_FOR_CLAIMS = gql`
  query {
    mltcsForClaims {
        id
        name
        sfAuthorizations {
            id
        }
        Clock {
            id
            authorizedHours
        }
    }
  }
`

export const CREATE_BILLING = gql`
  mutation($data: [CreateClaimBatchInput]! ) {
    createClaimBatchs(data: $data) {
        id
    }
  }
`

export const CLAIM_BY_ID = gql`
  query($id: String!){
    claimById(id: $id){
      id
      type
      createdAt
      claimBatchId
      patientId
      patient{
        firstName
        lastName
      }
      claimLines{
        id
        dateOfService
        status
        claimDetails{
          id
          billingUnitsClaimed
          amountClaimed
          evv {
            clearedForSubmission
            isSubmitted
            errorReason
            errorReasonFull
          }
          clock{
            clockIn
            clockOut
            caregiver {
              firstName
              lastName
            }  
          }
          authorizedHours
        }

      } 
    }
  }
`

export const SUBMIT_EVV_RECORD = gql`
  mutation ($claimDetailId: String!) {
    submitEvvRecord(claimDetailId: $claimDetailId)
  }
`

export const SUBMIT_EVVS_FOR_BATCH = gql`
  mutation ($claimBatchIds: [String]!) {
    submitEvvsForBatch(claimBatchIds: $claimBatchIds) {
      errors
      successes
    }
  }
`

export const VIEW_EVV_RECORD = gql`
  query viewEvvRecord($claimDetailId: String!) {
    viewEvvRecord(claimDetailId: $claimDetailId) {
      id
      sfCaregiver {
        id
        firstName
        lastName
      }
      client {
        medicaidId
        dob
      }
      authorization {
        procedureCode
      }
      dailyTimeCard {
        clockIn
        clockOut
        serviceStartLocation
        serviceEndLocation
      }
    }
  }
`
