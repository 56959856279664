import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles';
import {
  Button, Dialog, DialogTitle, TextField, Box, Typography, DialogActions, Link,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { useMutation, useQuery } from '@apollo/client'
import { Theme } from 'src/theme'
import { CAREGIVERS, CREATE_CLOCK, PATIENTS } from 'src/apollo/queries'
import { useFormState } from 'src/hooks/useFormState'
import { Autocomplete } from '@mui/lab'
import throat from 'throat'
import { eachDayOfInterval } from 'date-fns'
import ClockList from './ClockList'
import DateRangeComponent from '../DateRangeComponent'

const PREFIX = 'CreateClockModal';

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  avatarContainer: `${PREFIX}-avatarContainer`,
  button: `${PREFIX}-button`,
  bottomButton: `${PREFIX}-bottomButton`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    '& .MuiDialog-paper': {
      padding: '15px 25px'
    },
    '& .MuiDialogTitle-root': {
      padding: '5px 0'
    },
    '& .MuiFormControl-root': {
      marginBottom: '15px'
    },
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.primary.main,
    marginRight: '2px'
  },

  [`& .${classes.avatarContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },

  [`& .${classes.button}`]: {
    marginBottom: '10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
  },

  [`& .${classes.bottomButton}`]: {
    marginBottom: '10px'
  }
}));

interface ClockModalProps {
  open: boolean,
  setOpen: Function,
  caregiverId?: string,
  patientId?: string,
  date?: { startDate: Date, endDate: Date },
}
const CreateClockModal = (props: ClockModalProps) => {
  const {
    open, setOpen, caregiverId, patientId, date,
  } = props

  const {
    formState, handleValueChange, handleClear, setFormState
  } = useFormState({
    initialState: {
      values: {
        caregiverId,
        sFPatientId: patientId,
        date: date || null,
        timeIn: '',
        timeOut: '',
        note: '',
      }
    },
    schema: {
      caregiverId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        }
      },
      sFPatientId: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        }
      },
      timeIn: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        }
      },
      timeOut: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        }
      },
      date: {
        presence: {
          allowEmpty: false,
          message: 'is required',
        }
      },

    }
  })
  const [clocksToCreate, setClocksToCreate] = React.useState([])
  const [patientSearch, setPatientSearch] = React.useState('')
  const [caregiverSearch, setCaregiverSearch] = React.useState('')
  const [statuses, setStatuses] = React.useState(null)
  const [createClock, { loading }] = useMutation(CREATE_CLOCK, {
    refetchQueries: ['getCaregiverClocks']
  })

  const { data: patientData } = useQuery(PATIENTS, {
    variables: {
      data: {
        search: patientSearch,
        take: 40,
        skip: 0,
        caregiverId: formState.values.caregiverId,
        patientId: formState.values.sFPatientId
      }
    },
  })

  const { data: caregiverData } = useQuery(CAREGIVERS, {
    variables: {
      data: {
        search: caregiverSearch,
        take: 40,
        skip: 0,
        patientId: formState.values.sFPatientId,
        caregiverId: formState.values.caregiverId,
      }
    },
  })
  const handleClose = () => { handleClear(); setClocksToCreate([]); setStatuses(null); setOpen(false); }
  const isFutureDate = useMemo(() => {
    const today = new Date()
    return formState.values.date?.startDate > today || formState.values.date?.endDate > today
  }, [formState.values.date])

  const isValid = !isFutureDate && formState?.isValid && +formState.values.timeIn.replace(':', '.') < +formState.values.timeOut.replace(':', '.')
  console.log('🚀 ~ CreateClockModal ~ isValid:', isValid)
  console.log(isFutureDate)
  console.log(formState?.isValid, 'isValid')
  console.log(formState)

  const addToClocksToCreate = () => {
    console.log('formState.isValid', formState.isValid)
    if (!isValid) return clocksToCreate;
    console.log('formState.values', formState.values)
    const {
      date, timeIn, timeOut, note, caregiverId, sFPatientId
    } = formState.values
    const splitTimeIn = timeIn.split(':')
    const splitTimeOut = timeOut.split(':')

    const allClocksToCreate = ([
      ...(clocksToCreate || []),
      ...eachDayOfInterval({ start: date?.startDate, end: date?.endDate }).map((date) => {
        const clockIn = new Date(
          date.getFullYear(), date.getMonth(), date.getDate(), parseFloat(splitTimeIn?.[0] || '0'), parseFloat(splitTimeIn?.[1] || '0')
        )
        const clockOut = new Date(
          date.getFullYear(), date.getMonth(), date.getDate(), parseFloat(splitTimeOut?.[0] || '0'), parseFloat(splitTimeOut?.[1] || '0')
        )
        return {
          clockIn,
          clockOut,
          note,
          caregiverId,
          sFPatientId
        }
      })])
    setClocksToCreate(allClocksToCreate)
    setFormState({
      ...formState,
      values: {
        ...formState.values, date: null, timeIn: '', timeOut: '', note: ''
      }
    })
    return allClocksToCreate;
  }

  React.useEffect(() => {
    if (caregiverData && !caregiverData?.caregivers?.results?.some((c) => c?.id === formState.values?.caregiverId)) handleValueChange('caregiverId', '')
    if (caregiverData?.caregivers?.results?.length === 1) handleValueChange('caregiverId', caregiverData?.caregivers?.results?.[0]?.id)
  }, [caregiverData])

  React.useEffect(() => {
    if (!formState.values.caregiverId) handleValueChange('caregiverId', caregiverId)
  }, [caregiverId])

  React.useEffect(() => {
    if (patientData && !patientData?.patients?.results?.some((p) => p?.id === formState.values?.sFPatientId)) handleValueChange('sFPatientId', '')
    if (patientData?.patients?.results?.length === 1) handleValueChange('sFPatientId', patientData?.patients?.results?.[0]?.id)
  }, [patientData])

  return (
    <StyledDialog className={classes.root} onClose={handleClose} open={open} maxWidth="md">
      <Box width="650px">
        <DialogTitle id="simple-dialog-title" style={{ marginBottom: '10px', minWidth: 600 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Add a Clock</Typography>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Box>
        </DialogTitle>
        <Box display="flex" style={{ backgroundColor: '#eceff1', borderRadius: '12px' }} p={2} pt={1}>
          <Box display="flex" flexDirection="column" mr={3}>
            <Typography variant="h6">Patient</Typography>
            <Autocomplete
              id="patient"
              options={patientData?.patients?.results || []}
              freeSolo
              size="small"
              fullWidth
              style={{
                backgroundColor: 'white', maxHeight: 40, minWidth: 240
              }}
              value={patientData?.patients?.results?.find((p) => p?.id === formState.values?.sFPatientId) || null}
              onChange={(e, v) => {
                if (v === null) {
                  setPatientSearch('')
                  handleValueChange('sFPatientId', '')
                  handleValueChange('caregiverId', '')
                }
                handleValueChange('sFPatientId', v?.id || undefined);
              }}
              getOptionLabel={(option: any) => `${option?.firstName || ''} ${option?.lastName || ''}`}
              renderInput={(params) => (
                <TextField
                  size="small"
                  value={patientSearch}
                  onChange={(e) => {
                    setPatientSearch(e.target.value);
                  }}
                  {...params}
                  placeholder="Select Patient"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" style={{ marginLeft: 2 }}>Caregiver</Typography>
            <Autocomplete
              id="caregiver"
              options={caregiverData?.caregivers?.results || []}
              freeSolo
              size="small"
              fullWidth
              style={{
                backgroundColor: 'white', maxHeight: 40, marginLeft: 2, minWidth: 240
              }}
              value={caregiverData?.caregivers?.results?.find((c) => c?.id === formState.values?.caregiverId)
                || null}
              onChange={(e, v) => {
                handleValueChange('caregiverId', v?.id || undefined)
                if (v === null) {
                  setCaregiverSearch('')
                  handleValueChange('sFPatientId', '')
                }
              }}
              getOptionLabel={(option: any) => `${option?.sfContact?.firstName || option.firstName || ''} ${option?.sfContact?.lastName || option.lastName || ''}`}
              renderInput={(params) => <TextField value={caregiverSearch} onChange={(e) => setCaregiverSearch(e.target.value)} size="small" {...params} placeholder="Select Caregiver" variant="outlined" fullWidth />}
            />
          </Box>
        </Box>
        <ClockList
          clocks={clocksToCreate}
          statuses={statuses}
          updateClocksToCreate={
            ({ index, value }) => setClocksToCreate([...clocksToCreate.slice(0, index), value, ...clocksToCreate.slice(index + 1)])
          }
        />
        {!statuses && (
          <>
            <Typography variant="h6" style={{ marginTop: 20 }}>Clock Duration</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DateRangeComponent
                key={formState.values.date?.startDate?.toString() || 'date-picker'}
                error={isFutureDate}
                timePeriodOptions={[]}
                selectionRange={formState.values.date || { startDate: null, endDate: null }}
                allowClearable
                helperText={isFutureDate ? 'Date cannot be in the future' : ''}
                placeholder="Select date / range"
                style={{ margin: '0', minWidth: '240px', width: '240px' }}
                setSelectionRange={(value) => handleValueChange('date', value)}
                onTimePeriodChange={(e, value) => handleValueChange('date', value)}
              />
              <TextField
                type="time"
                placeholder="Start Time"
                size="small"
                variant="outlined"
                style={{ width: '150px', marginRight: '5px', marginLeft: '5px' }}
                value={formState.values.timeIn}
                onChange={(e) => {
                  handleValueChange('timeIn', e.target.value)
                }}
                error={formState.values.timeOut && +formState.values.timeIn.replace(':', '.') > +formState.values.timeOut.replace(':', '.')}
                helperText={formState.values.timeOut && +formState.values.timeIn.replace(':', '.') > +formState.values.timeOut.replace(':', '.') ? 'Clock in must be before clock out' : ''}
                id="clockin-date-picker"
              />
              <TextField
                type="time"
                placeholder="End Time"
                size="small"
                variant="outlined"
                style={{ width: '150px' }}
                value={formState.values.timeOut}
                onChange={(e) => {
                  handleValueChange('timeOut', e.target.value)
                }}
                id="clockout-date-picker"
              />
              <Link
                disabled={!isValid}
                onClick={addToClocksToCreate}
                color="textPrimary"
                component="button"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography>Add Clocks</Typography>
              </Link>
            </div>
            <TextField value={formState.values.note} multiline size="small" label="Note" fullWidth variant="outlined" onChange={(e) => handleValueChange('note', e.target.value)} />
            <Link
              disabled={!isValid}
              onClick={addToClocksToCreate}
              color="textPrimary"
              component="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <AddIcon />
              <Typography>Add Another Clock</Typography>
            </Link>
            <DialogActions>
              <Button className={classes.bottomButton} onClick={handleClose}>Cancel</Button>
              <Button
                className={classes.button}
                disabled={(!isValid && clocksToCreate?.length === 0) || statuses?.length > 0 || loading}
                onClick={() => {
                  const allClocksToCreate = addToClocksToCreate();
                  const status: any[] = [];
                  allClocksToCreate
                    ?.forEach(throat(1, async (c, index) => !!c && createClock({
                      variables: {
                        data: c
                      }
                    }).then(() => { status.push({ index, status: 'success' }); setStatuses([...(status), { index, status: 'success' }]); })
                      .catch((e) => { status.push({ index, status: 'failed', error: e?.message }); setStatuses([...(status), { index, status: 'failed', error: e?.message }]); })))
                  handleClear()
                }}
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
    </StyledDialog>
  );
}

export default CreateClockModal
