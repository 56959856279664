import { gql } from '@apollo/client'

export const RULE_GROUPS = gql`
    query($data: RuleGroupQuery!) {
        ruleGroups(data: $data){
            id
            name
            createdAt
            rules {
                restriction
                restrictionValue
                action
                id
            }
            default
        }
    }
`

export const RULE_GROUP = gql`
    query($id: ID!) {
        ruleGroup(id: $id){
            id
            name
            createdAt
            default
            rules {
                restriction
                restrictionValue
                action
                id
                createdAt
                updatedAt
            }
            caregivers {
                firstName
                lastName
                id
                sfContactId
            }
        }
    }
`

export const CREATE_RULE_GROUP = gql`
    mutation($name: String!, $rules: [RulesInputWithGroup!]) {
        createRuleGroup(name: $name, rules: $rules) {
            id
            name
            createdAt
            rules {
                restriction
                restrictionValue
                action
                id
                createdAt
                updatedAt
            }
        }
    }
`

export const DELETE_RULE_GROUP = gql`
    mutation($id: ID!) {
        deleteRuleGroup(id: $id) {
            id
        }
    }
`
export const DELETE_CG_FROM_RULE_GROUP = gql`
    mutation($ruleGroupId: String!, $caregiverId: String!) {
        deleteCgFromRuleGroup(ruleGroupId: $ruleGroupId, caregiverId: $caregiverId){
            id
            firstName
            lastName
            usesCustomRules
            ruleGroup{
                id
            }
        }
    }
`

export const ADD_CG_TO_RULE_GROUP = gql`
    mutation($ruleGroupId: String!, $caregiverId: String!) {
        addCgToRuleGroup(ruleGroupId: $ruleGroupId, caregiverId: $caregiverId){
            id
            firstName
            lastName
            usesCustomRules
            ruleGroup{
                id
            }
        }
    }
`

export const UPDATE_RULE_GROUP_NAME = gql`
    mutation($id: String!, $name: String!) {
        updateRuleGroupName(id: $id, name: $name) {
            id
            name
            createdAt
            rules {
                restriction
                restrictionValue
                action
                id
                createdAt
                updatedAt
            }
        }
    }
`

export const SET_RULE_GROUP_AS_DEFAULT = gql`
    mutation($ruleGroupId: ID!) {
        setDefaultRuleGroup(ruleGroupId: $ruleGroupId) {
            id
            name
            createdAt
            default
        }
    }
`
