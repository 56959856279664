import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { createClient } from 'src/utils'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { SettingsProvider } from 'src/contexts/SettingsContext'
import { AuthProvider } from 'src/contexts/JWTAuthContext'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'react-perfect-scrollbar/dist/css/styles.css';

const client = createClient()
const renderApp = () => {
  ReactDOM.render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider maxSnack={3}>
        <SettingsProvider>
          <ApolloProvider client={client}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ApolloProvider>
        </SettingsProvider>
      </SnackbarProvider>
    </LocalizationProvider>,
    document.getElementById('root')
  )
}

renderApp()

serviceWorker.unregister()
