import { gql } from '@apollo/client'

export const USERS = gql`
  query($data: UsersQuery!) {
    users(data: $data) {
      count
      results {
        id
        email
        role
        inactive
        firstName
        lastName
        phone
        sfUser {
        id
        sfid
        fullName
      }
      }
    }
  }
`
export const USERS_BY_ID = gql`
  query($ids: [String!]!) {
    usersById(ids: $ids) {
        id
        email
        role
        inactive
        firstName
        lastName
        phone
        sfUser {
        id
        sfid
        fullName
      }
    }
  }
`

export const USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      role
      inactive
      firstName
      lastName
      phone
      sfUser {
        id
        email
        fullName
      }
    }
  }
`
export const UPDATE_USER = gql`
mutation updateUser($user: UserInput) {
  updateUser(user: $user) {
    id
    role
    email
    firstName
    lastName
    sfUserId
    settings {
      mltcDetails {
        exceptions
        coordinatorId
        mltcId
        startDate
        endDate
        status
        clockStatus
      }
      tableFilters {
          table
          hidden_columns
          filters{
            id
            name
            isActive
            options {
              key
              value
            }
          }
        }
    }
  }
}
`

export const CREATE_USER = gql`
  mutation($employee: UserCreateInput!) {
    createUser(user: $employee) {
      id
    }
  }
`
