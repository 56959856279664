import { gql } from '@apollo/client'

export const PATIENTS = gql`
    query($data: SFPatientQuery!) {
        patients(data: $data){
            results {
                id
                sfid
                sfExternalId
                firstName
                lastName
                shippingStreet
                shippingCity
                shippingState
                shippingPostalCode
                accountType
                dob
                Clock {
                    id
                    clockIn
                    clockOut
                    claimDetails {
                        id
                    }
                    payrollDetails {
                        id
                        hoursPaid
                    }
                }
                sfAuthorizations {
                  recordType
                  mltcId
                  authStartDate
                  authEndDate
                  mltc {
                      id
                  }
                  hoursPerWeek
                }
                sfAccountContactRelation {
                    allowClockIn
                    roleType
                    sfContact {
                        id
                        firstName
                        lastName
                    }
                }
            }
            count
        }
    }
`

export const TOTAL_AUTH = gql`
    query($startDate: Date!, $endDate: Date!){
        totalAuth(startDate: $startDate, endDate: $endDate)
    }
`

export const PATIENT = gql`
    query($id: ID!, $clockStartDate: Date, $clockEndDate: Date) {
        patient(id: $id, clockStartDate: $clockStartDate, clockEndDate: $clockEndDate){
            id
            sfid
            sfExternalId
            firstName
            lastName
            shippingStreet
            shippingCity
            shippingState
            shippingPostalCode
            accountType
            Clock {
                id
                clockIn
                clockOut
                caregiverId
                status
                estimatedPay
                flags {
                    exceededBy
                    restrictionMessage
                    restrictionName
                    restrictionValue
                    unit
                    approvalHoursPay
                    rejectedHoursPay
                    status
                    note
                    reason
                    }
                
            }
            weeklyUtilizationResolutions {
                reason
                patientId
                startOfWeek
                note
            }
            cdpapCoordinator{
                id
                fullName
            }
            sfAccountContactRelation {
                    allowClockIn
                    sfAccount{
                        id
                        firstName
                        lastName
                        sfExternalId
                    }
                    sfContact {
                        id
                        sfid
                        employeeNumber  
                        firstName
                        lastName
                        caregiver {
                            id
                            firstName
                            lastName
                        }
                    }
            }
            sfAuthorizations{
                    id
                    activeAuth
                    authEndDate
                    authStartDate
                    recordType
                    hoursPerWeek
                    daysPerWeek
                    hoursPerDay
                    daysPerWeek2
                    hoursPerDay2
                    sundayHours
                    mondayHours
                    tuesdayHours
                    wednesdayHours
                    thursdayHours
                    fridayHours
                    saturdayHours
                    mltc {
                        id
                        sfid
                        name
                    }
            }
        }
    }
`

export const STATS_FOR_UTILIZATION_REPORT = gql`
    query($startDate: Date!, $endDate: Date!){
        statsForUtilizationReport(startDate: $startDate, endDate: $endDate) {
            caregiversCount,
            clocks {
                clockIn
                clockOut
            }
            authHours
        }
    }
`

export const PATIENTS_FOR_UTILIZATION = gql`
    query patientsForUtilization($data: SFPatientUtilizationQuery!) {
        patientsForUtilization(data: $data){
            count
            results {
                id
                name
                firstName
                lastName
                sfExternalId
                cdpapCoordinatorId
                reason
                startOfWeek
                resNote
                resolvedByFirstName
                resolvedByLastName
                authStartDate
                authEndDate
                hoursPerWeek
                mltcName
                mltcId
                contactRelations
                clockIds
                sfid
                flagIds
                totalRoundedSecondsWorked
                deleted
                approvalHours
                totalClocks
                totalClocksWithGeoInAndOut
                coordname
            }
        }
    }
`
