import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles';
import {
  Button, Dialog, TextField, Typography, Link, Box,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { Autocomplete } from '@mui/lab'
import { useMutation, useQuery } from '@apollo/client'
import { CAREGIVERS, CREATE_COMP_TIMES, GET_PTO_BY_EMPLOYEE_NUMBER } from 'src/apollo/queries'
import AddIcon from '@mui/icons-material/Add'
import { pathOr } from 'ramda'
import { eachDayOfInterval } from 'date-fns'
import debounce from 'lodash.debounce'
import { Theme } from 'src/theme'
import { Caregiver } from 'src/types/__generated__/typescript-operations'

import PTOList from './PTOList'
import DateRangeComponent from '../DateRangeComponent'

const PREFIX = 'CompTimeModal';

const classes = {
  root: `${PREFIX}-root`,
  saveButton: `${PREFIX}-saveButton`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }: {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    '& .MuiDialog-paper': {
      padding: '15px 25px'
    },
  },

  [`& .${classes.saveButton}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    marginLeft: '5px'
  }
}));

const minutesToDecimal = {
  0: 0,
  15: 0.25,
  30: 0.5,
  45: 0.75,
}

interface CompTimeModalProps {
  open: boolean,
  setOpen: Function,
  isJuryDuty?: boolean,
  caregiver?: Caregiver,
  date?: { startDate: Date, endDate: Date },
}

const CompTimeModal = (props: CompTimeModalProps) => {
  const {
    open, setOpen, isJuryDuty, caregiver
  } = props

  const today = React.useMemo(() => new Date(), [])
  const [debouncedSearchCgs, setDebouncedSearchCgs] = useState('')
  const [selectedCg, setSelectedCg] = useState<any>(caregiver)
  const [date, setDate] = useState(props.date || null)
  const [note, setNote] = useState<string>()
  const [hours, setHours] = useState<string>(null)
  const [minutes, setMinutes] = useState<string>(null)
  const [compTimesToCreate, setCompTimesToCreate] = useState([])

  useEffect(() => {
    if (caregiver) {
      setSelectedCg(caregiver)
    }
  }, [caregiver])

  const handleClose = () => {
    setCompTimesToCreate([])
    setSelectedCg(undefined)
    setDebouncedSearchCgs('')
    setNote(undefined)
    setDate(null)
    setHours(null)
    setMinutes(null)
    setOpen(false)
  }

  const { enqueueSnackbar } = useSnackbar()
  const [createCompTimes] = useMutation(CREATE_COMP_TIMES)

  const { data, loading } = useQuery(GET_PTO_BY_EMPLOYEE_NUMBER, {
    variables: {
      employeeNumber: selectedCg?.sfContact?.employeeNumber,
      effectiveDate: today,
    },
    skip: !selectedCg?.sfContact?.employeeNumber,
  })

  const addToCreateComptTimes = () => {
    if ((!hours && !minutes) || !selectedCg || !date) return compTimesToCreate
    const allComptTimes = (
      [
        ...(compTimesToCreate || []),
        ...eachDayOfInterval({ start: date?.startDate, end: date?.endDate }).map((date) => ({
          note, hours, minutes, selectedCg, date, isJuryDuty
        }))
      ]
    )
    setNote('')
    setDate(null)
    setSelectedCg(null)
    setHours(null)
    setMinutes(null)
    setCompTimesToCreate(allComptTimes)
    return allComptTimes;
  }

  const caregiverQuery = useQuery(CAREGIVERS, {
    variables: {
      data: {
        search: debouncedSearchCgs,
        take: 50,
      }
    }
  })
  const caregiverList: [] = pathOr([], ['caregivers', 'results'], caregiverQuery.data)

  const totalComptime = useMemo(() => {
    const totalCreatedHours = compTimesToCreate.reduce((acc, d) => {
      const hours = ((d.hours || d.minutes)) ? +(d.hours || '0') + (minutesToDecimal[+d.minutes || 0]) : 0;
      return acc + hours
    }, 0)
    const currentHours = (hours || minutes) ? +(hours || '0') + (minutesToDecimal[+minutes || 0]) : 0;
    const datesInRange = date?.startDate && date?.endDate ? eachDayOfInterval({ start: date?.startDate, end: date?.endDate }).length : 0;
    const totalCurrentHours = datesInRange * currentHours

    return totalCreatedHours + totalCurrentHours
  }, [compTimesToCreate, hours, minutes, date])

  const availableHours = useMemo(() => {
    if (!data?.ptoByEmployeeNumber) {
      return 0;
    }
    return (data.ptoByEmployeeNumber.allowedAmount - data.ptoByEmployeeNumber.takenAmount).toFixed(2)
  }, [data])

  const debounceCg = debounce((p) => {
    setDebouncedSearchCgs(p)
  }, 1000)

  const caregiverOptions = (option) => {
    if (option.sfContact?.employeeNumber) {
      return `${option.sfContact?.firstName} ${option.sfContact?.lastName} - ${option.sfContact?.employeeNumber}`
    }
    return `${option.sfContact?.firstName} ${option.sfContact?.lastName}`
  }

  return (
    <StyledDialog className={classes.root} onClose={handleClose} open={open}>
      <Box width="550px">
        <Typography style={{ marginBottom: 10 }}>{`Add ${isJuryDuty ? '(Jury Duty)' : ''} PTO`}</Typography>
        <PTOList
          compTimesToCreate={compTimesToCreate}
          updateComptTimesToCreate={({ index, value }) => setCompTimesToCreate(
            [...compTimesToCreate.slice(0, index), value, ...compTimesToCreate.slice(index + 1)]
          )}
        />
        <Box display="flex" style={{ backgroundColor: '#eceff1', borderRadius: '12px' }} p={2} pt={1}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">Caregiver</Typography>
            <Autocomplete
              id="caregiver"
              size="small"
              fullWidth
              style={{
                backgroundColor: 'white', maxHeight: 40, minWidth: 240, marginBottom: isJuryDuty ? '30px' : undefined
              }}
              options={caregiverList}
              value={selectedCg || null}
              onInputChange={(e, v) => debounceCg(v)}
              onChange={(e, v) => setSelectedCg(v)}
              getOptionLabel={caregiverOptions}
              renderInput={(params) => <TextField {...params} variant="outlined" required />}
            />
          </Box>
        </Box>
        {!isJuryDuty && (
          <div style={{ margin: '15px 0' }}>
            Available Hours
            {' '}
            {loading
              ? <i>Loading...</i>
              : (
                <>
                  {availableHours}
                </>
              )}
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DateRangeComponent
            timePeriodOptions={[]}
            selectionRange={date || { startDate: null, endDate: null }}
            allowClearable
            placeholder="Select date / range"
            style={{ margin: 0 }}
            setSelectionRange={(value) => setDate(value)}
            onTimePeriodChange={(e, value) => setDate(value)}
          />
          <span style={{ width: '5px' }} />
          <Autocomplete
            clearIcon={null}
            id="hours"
            size="small"
            value={hours}
            style={{ width: '90px', marginRight: '5px' }}
            onChange={(e, v) => setHours(v)}
            options={Array.from(Array(12).keys()).map((i) => (i + 1).toString())}
            renderInput={(params) => <TextField {...params} label="Hours" variant="outlined" />}
          />
          <Autocomplete
            clearIcon={null}
            id="minutes"
            size="small"
            value={minutes}
            style={{ width: '90px' }}
            onChange={(e, v) => setMinutes(v)}
            options={['00', '15', '30', '45']}
            renderInput={(params) => <TextField {...params} label="Minutes" variant="outlined" />}
          />
          <span style={{ width: '5px' }} />
          <Link
            disabled={!date || (!hours && !minutes) || !selectedCg}
            onClick={addToCreateComptTimes}
            color="textPrimary"
            component="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography>Add PTO</Typography>
          </Link>
        </div>
        <TextField size="small" variant="outlined" label="Note" style={{ margin: '15px 0' }} multiline maxRows={3} fullWidth value={note} onChange={(e) => setNote(e.target.value)} />
        <Link
          disabled={!date || (!hours && !minutes) || !selectedCg}
          onClick={addToCreateComptTimes}
          color="textPrimary"
          component="button"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <AddIcon />
          <Typography>Add Another PTO</Typography>
        </Link>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            disabled={(!compTimesToCreate.length && ((!hours && !minutes) || !selectedCg || !date)) || totalComptime > availableHours}
            variant="outlined"
            className={classes.saveButton}
            onClick={() => {
              const comptToCreate = addToCreateComptTimes()
              createCompTimes({
                variables: {
                  data: comptToCreate.map((d) => ({
                    hours: ((d.hours || d.minutes)) ? +(d.hours || '0') + (minutesToDecimal[+d.minutes || 0]) : null, date: d.date, note: d.note, caregiverId: d.selectedCg?.id, isJuryDuty: d.isJuryDuty
                  }))
                }
              }).then(() => enqueueSnackbar('Comp Time succesfully created', { variant: 'success' }))
                .then(() => handleClose())
                .catch(() => enqueueSnackbar('Something went wrong', { variant: 'error' }))
            }}
          >
            Save
          </Button>
        </div>
      </Box>
    </StyledDialog>
  );
}

export default CompTimeModal
