import { gql } from '@apollo/client'

export const GLOBAL_SEARCH = gql`
    query($search: String!, $searchFilter: SearchFilter) {
        search(search: $search, searchFilter: $searchFilter) {
            users {
                firstName
                lastName
                id
                email
                phone
            }
            patients {
                firstName
                lastName
                shippingStreet
                shippingCity
                shippingState
                shippingPostalCode
                id
            }
            caregivers {
                firstName
                lastName
                id
                phone
                email
            }
        }
    }
`
