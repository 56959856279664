import { gql } from '@apollo/client'

export const CLOCK_REQUESTS = gql`
    query($data: ClockRequestsInput!) {
        clockRequests(data: $data) {
            count
            results {
                id
                clockId
                clock {
                    id
                    patient {
                        firstName
                        lastName
                        id
                        sfExternalId
                    }
                    payrollDetails{
                        id
                    }
                }
                oldClockIn
                oldClockOut
                oldPatientId
                requestedClockIn
                requestedClockOut
                requestedPatientId
                requestedPatient {
                    firstName
                    lastName
                    id
                    sfid
                    sfExternalId
                }
                createdAt
                note
                status
                caregiver {
                    firstName
                    lastName
                    id
                    sfContact{
                        id
                        employeeNumber 
                        firstName
                        lastName 
                    }
                }
                patient {
                    firstName
                    lastName
                    id
                    sfid
                    sfExternalId
                }
            }
        }
    }
`

export const APPROVE_CLOCK_REQUEST = gql`
    mutation($id: String!) {
        approveClockRequest(id: $id) {
            id
            clockId
            oldClockIn
            oldClockOut
            oldPatientId
            requestedClockIn
            requestedClockOut
            clock {
                patient {
                    firstName
                    lastName
                    id
                }
            }
            requestedPatientId
            note
            status
            caregiver {
                firstName
                lastName
                id
            }
            patient {
                firstName
                lastName
                id
            }
        }
    }
`

export const DENY_CLOCK_REQUEST = gql`
    mutation($id: String!) {
        denyClockRequest(id: $id) {
            id
            clockId
            oldClockIn
            oldClockOut
            oldPatientId
            requestedClockIn
            requestedClockOut
            clock {
                patient {
                    firstName
                    lastName
                    id
                }
            }
            requestedPatientId
            note
            status
            caregiver {
                firstName
                lastName
                id
            }
            patient {
                firstName
                lastName
                id
            }
        }
    }
`

export const DELETE_CLOCK_REQUEST = gql`
    mutation($id: String!) {
        deleteClockRequest(id: $id) {
            id
        }
    }
`

export const EDIT_CLOCK_REQUEST = gql`
  mutation($data: EditClockRequestInput!){
        editClockRequest(data: $data){
            id
            requestedClockIn
            requestedClockOut
            note
        }
    }
`
