import { gql } from '@apollo/client'

export const TIMESHEETS = gql`
  query($data: TimesheetsQueryInput) {
    timesheets(data: $data){
      results{
        user {
          id
          lastName
          firstName
        }
        timesheets {
          totalTracked
          totalClocked
          date
          confirmed
          clocks{
            id
            clockIn
            clockOut
          }
        timeEntries {
            id
            date
            duration
            clockIn
            clockOut
            note
          }
        }
      }
    }
  }
`

export const CONFIRM_DATE = gql`
mutation($data:ConfirmDateInput){
  confirmDate(data:$data)
}
`
export const UNCONFIRM_DATE = gql`
mutation($data:ConfirmDateInput){
  unconfirmDate(data:$data)
}
`
