import { gql } from '@apollo/client'

export const CLAIM_BATCHES = gql`
  query ($data: ClaimBatchesQuery!) {
    claimBatches(data: $data) {
      count
      results {
        claimBatchIds
        createdAt
        totalAuthorizedHours
        totalMltcAuthHours
        startDate
        endDate
        totalAmountOfClaims
        totalHoursWorked
        totalAmountClaimed
      }
    }
  }
`

export const CLAIM_BATCHES_BY_SUBMITTED_DATE = gql`
  query ($data: ClaimBatchesBySubmittedDateInput!) {
    claimBatchesBySubmittedDate(data: $data) {
      id
      createdAt
      mltcName
      status
      mltcId
      controlNumber
      totalAuthorizedHours
      totalMltcAuthHours
      startDate
      endDate
      totalAmountOfClaims
      totalHoursWorked
      totalAmountClaimed
      evvMissingInfo
      evvReadyForSubmission
      evvSubmitSuccessfully
      evvSubmitErrors
    }
  }
`

export const UPDATE_CLAIM_BATCH_STATUS = gql`
  mutation ($id: String!, $status: ClaimBatchStatus!) {
    updateClaimBatchStatus(id: $id, status: $status) {
      id
      status
    }
  }
`

export const CREATE_CLAIM_BATCH = gql`
  mutation ($data: CreateClaimBatchInput!) {
    createClaimBatchs(data: $data) {
      id
      status
      claim {
        patientId
        claimLines {
          claimDetails {
            authorizedHours
          }
        }
      }
    }
  }
`

export const GENERATE_EDI_FILE = gql`
  mutation ($claimBatchId: String!) {
    generateEdiFile(claimBatchId: $claimBatchId) {
      file
      controlNumber
      mltcName
    }
  }
`

export const CLAIMS_BY_BATCHID = gql`
  query ($claimBatchId: String!) {
    claimsByBatchId(id: $claimBatchId) {
      id
      patient {
        firstName
        lastName
        sfExternalId
      }
      authorizedHours
      totalMltcAuthHours
      amountOfClocks
      secondsWorked
      amountClaimed
      claimLineStatuses
      evvErrorReasons
    }
  }
`

export const PATIENTS_FOR_ClAIM = gql`
  query ($data: PatientsForClaimInput!) {
    patientsForClaim(data: $data) {
      sfid
      firstName
      lastName
      mltcId
      totalMltcAuthHours
      totalPrevHoursBilled
      totalBilledHours
      id
      clocks {
        id
        clockIn
        clockOut
        hoursPaid
        secondsWorked
      }
    }
  }
`

export const DELETE_CLAIM_BATCH = gql`
  mutation ($id: String!) {
    deleteClaimBatch(id: $id) {
      id
    }
  }
`

export const CLAIM_DETAILS_BY_BATCH_ID = gql`
  query ($claimBatchId: String!) {
    claimBatchDetails(claimBatchId: $claimBatchId) {
      id
      controlNumber
      mltcName
      patientId
      patientName
      dateOfService
      authorizedHours
      clockIn
      clockOut
      amountClaimed
      billingUnitsClaimed
      caregiverName
      createdAt
      caregiverId
    }
  }
`

export const CLAIM_BATCH_EVV_ERRORS = gql`
  query ($claimBatchId: String!) {
    claimBatchEvvErrors(claimBatchId: $claimBatchId) {
      controlNumber
      mltcName
      patientId
      patientName
      caregiverName
      caregiverId
      employeeNumber
      evvError
    }
  }
`
