import { gql } from '@apollo/client'

export const RESTRICTION_TYPES = gql`
    query {
        restrictionTypes {
            name
            displayName
            keyboardType
            actionTypes
            approvalDescription
            rejectionDescription
        }
    }
`

export const CREATE_RULE = gql`
    mutation($data: CreateRuleInput!) {
        createRule(data: $data) {
            id
            createdAt
            updatedAt
            action
            restriction
            restrictionValue
            ruleGroupId
        }
    }
`

export const CREATE_RULES = gql`
    mutation($data: CreateRulesInput!) {
        createCustomRules(data: $data) {
            count
        }
    }
`

export const UPDATE_RULE = gql`
    mutation($data: UpdateRuleInput!) {
        updateRule(data: $data) {
            id
            createdAt
            updatedAt
            action
            restriction
            restrictionValue
            ruleGroupId
        }
    }
`

export const DELETE_RULE = gql`
    mutation($id: ID!) {
        deleteRule(id: $id) {
            id
        }
    }
`
