import React from 'react'
import {
  Box, Typography, Grid, Divider, TextField
} from '@mui/material'
import {
  Check as CheckIcon, Close as CloseIcon
} from '@mui/icons-material'
import moment from 'moment'
import { format } from 'date-fns'

interface ClockListProps {
  clocks: any[],
  statuses: any[],
  updateClocksToCreate: ({ index, value }) => void
}

const ClockList = (props: ClockListProps) => {
  const {
    clocks, statuses, updateClocksToCreate
  } = props
  return (
    <>
      {clocks?.map((cl, index) => {
        const status = statuses?.find((s) => s.index === index)
        return (
          <>
            <Grid container spacing={2} style={{ marginTop: 3 }}>
              <Grid item xs={3}>
                <Typography variant="body2">Date</Typography>
                <Typography>{moment(cl?.clockIn)?.format('M/DD/YY')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Time</Typography>

                <Box display="flex">
                  <TextField
                    type="time"
                    size="small"
                    variant="outlined"
                    value={format(new Date(cl.clockIn), 'HH:mm')}
                    onChange={(e) => {
                      const date = e.target.value?.split(':')
                      const { clockIn } = cl
                      const combinedDate = new Date(
                        clockIn.getFullYear(), clockIn.getMonth(), clockIn.getDate(), parseFloat(date?.[0] || '0'), parseFloat(date?.[1] || '0')
                      )
                      updateClocksToCreate({ index, value: { ...cl, clockIn: combinedDate } })
                    }}
                    id="clockin-date-picker"
                  />
                  <Typography variant="body2" style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }}>-</Typography>
                  <TextField
                    type="time"
                    size="small"
                    variant="outlined"
                    value={format(new Date(cl.clockOut), 'HH:mm')}
                    onChange={(e) => {
                      const date = e.target.value?.split(':')
                      const { clockOut } = cl
                      const combinedDate = new Date(
                        clockOut.getFullYear(), clockOut.getMonth(), clockOut.getDate(), parseFloat(date?.[0] || '0'), parseFloat(date?.[1] || '0')
                      )
                      updateClocksToCreate({ index, value: { ...cl, clockOut: combinedDate } })
                    }}
                    id="clockOut-date-picker"
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Total Hours</Typography>
                <Typography>
                  {moment(cl?.clockOut || undefined).diff(cl?.clockIn, 'hours')}
                  hrs
                  {' '}
                  {moment(cl?.clockOut || undefined).diff(cl?.clockIn, 'minutes') % 60}
                  m
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {status && (
                  (status?.status === 'success')
                    ? <CheckIcon style={{ color: 'green' }} />
                    : (
                      <Box display="flex" flexDirection="column">
                        <CloseIcon style={{ color: 'red' }} />
                        <Typography variant="body2">{status?.error}</Typography>
                      </Box>
                    )
                )}
              </Grid>
            </Grid>
            <Divider />
          </>
        )
      })}
    </>
  )
}

export default ClockList
