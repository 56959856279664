import { gql } from '@apollo/client'

export const SAVE_UTILIZATION_RESOLUTION = gql`
    mutation($resolution: ResolutionInput!){
        saveUtilizationResolution(resolution: $resolution){
            reason
            patientId
            startOfWeek
        }
    }
`

export const UTILIZATION_RESOLUTIONS = gql`
    query($patientId: String, $week: Date){
        utilizationResolutions(patientId: $patientId, week: $week){
            startOfWeek
            patientId
            reason
        }
    }
`

export const DELETE_UTILIZATION_RESOLUTION = gql`
    mutation($patientId: String, $week: Date){
        deleteUtilizationResolution(patientId: $patientId, week: $week) {
            patientId
            startOfWeek
        }
    }
`
