import { gql } from '@apollo/client'

export const CREATE_COMP_TIME = gql`
    mutation($data: CreateCompTimeInput!) {
      createCompTime(data: $data) {
        id
      }
    }
`

export const EDIT_COMP_TIME = gql`
    mutation($data: EditCompTimeInput!) {
      editCompTime(data: $data) {
        id
        hours
        isJuryDuty
        note
        date
      }
    }
`

export const CREATE_COMP_TIMES = gql`
    mutation($data: [CreateCompTimeInput]!) {
      createCompTimes(data: $data) {
        count
      }
    }
`

export const UPDATE_COMP_TIME = gql`
    mutation($compTime: UpdateCompTimeInput!) {
      updateCompTime(compTime: $compTime) {
        id
        status
      }
    }
`

export const COMP_TIMES = gql`
  query($data: PaginatedCompTimeInput!) {
    compTimes(data: $data){
      count
      results{
        id
        note
        hours
        createdAt
        date
        status
        isJuryDuty
        createdById 
        caregiverId
        payrollDetails {
          id
        }
        caregiver{
          id
          sfContact {
            id
            employeeNumber
            firstName
            lastName
            sfid
          }
        }
      }
    }
  }
`

export const COMP_TIMES_MULTIPLE_CGS = gql`
  query($data: MultipleCgsCompTimeInput!) {
    compTimesMultipleCgs(data: $data){
        id
        note
        hours
        createdAt
        date
        status
        isJuryDuty
        createdById 
        caregiverId
        payrollDetails {
          id
        }
        caregiver{
          id
          sfContact {
            id
            employeeNumber
            firstName
            lastName
            sfid
          }
        }
    }
  }
`

export const COMP_TIMES_BY_PATIENT_IDS = gql`
  query($data: PatientIdCompTimeInput!) {
    compTimeByPatientIds(data: $data){
        id
        hours
        date
        patientId
        status
        caregiver {
          sfContact {
            id
            employeeNumber
            firstName
            lastName
            sfid
          }
        }
        note
    }
  }
`

export const COMP_TIME_STATUS_TYPES = gql`
  query{
    compTimeStatusTypes
  }
`
